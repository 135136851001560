import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Form,
  Row,
  Col,
  Label,
  Button,
} from "reactstrap";
import { api } from "../api/api";

import img2 from "../assets/images/big/auth-bg.jpg";
import { setRoles } from "../redux/settings/Action";
import { getExchangeMargin } from '../redux/dictionary/Action';

const validators = {
  email: {
    rules: [
      {
        test: /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i,
        message: "Please Enter Valid Email",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  password: {
    rules: [
      {
        test: (value) => {
          return value.length >= 4;
        },
        message: "Password can not be < 4 characters",
      },
      {
        test: /^[a-z0-9A-Z_]+$/,
        message: "Enter Valid Password",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
  username: {
    rules: [
      {
        test: /^[a-zA-Z0-9_]+$/i,
        message: "",
      },
    ],
    errors: [],
    valid: false,
    state: "",
  },
};

const sidebarBackground = {
  backgroundImage: "url(" + img2 + ")",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
};

const Login2 = (props) => {
  const { t } = props;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [apiError, setApiError] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (apiError) {
      setApiError(false);
    }
  }, [username, password]);

  const onInputChange = (e) => {
    formValidators([e.target.name], e.target.value);
  };

  const formValidators = (fieldName, value) => {
    validators[fieldName].errors = [];
    validators[fieldName].state = value;
    validators[fieldName].valid = true;
    validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          validators[fieldName].errors.push(rule.message);
          validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          validators[fieldName].errors.push(rule.message);
          validators[fieldName].valid = false;
        }
      }
    });
  };

  const validForm = () => {
    let status = true;
    Object.keys(validators).forEach((field) => {
      if (field === "username" || field === "password") {
        if (!validators[field].valid) {
          status = false;
        }
      }
    });
    return status;
  };

  const showErrors = (fieldName) => {
    const validator = validators[fieldName];
    const result = "";
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            * {info}
            <br />
          </span>
        );
      });
      return <div className="error mb-2">{errors}</div>;
    }
    return result;
  };

  const doLogin = (event) => {
    const username1 = username;
    const password1 = password;
    api.auth(username1, password1).then(
      async (res) => {
        localStorage.setItem("login", username1);
        dispatch(setRoles(res.roles));
        history.push("/");
      },
      (rej) => setApiError(true)
    );
    event.preventDefault();
  };

  const auth = localStorage.getItem("token");

  return (
    <div
      className="auth-wrapper  align-items-center d-flex"
      style={sidebarBackground}
    >
      {auth && (
        <Redirect
          to={{
            pathname: "/",
            state: { from: history.location },
          }}
        />
      )}
      {/*--------------------------------------------------------------------------------*/}
      {/*Login2 Cards*/}
      {/*--------------------------------------------------------------------------------*/}
      <div className="container">
        <div>
          <Row className="no-gutters justify-content-center">
            <Col md="6" lg="4" className="bg-dark text-white">
              <div className="p-4">
                <h2 className="display-5">
                  <br />{" "}
                  <span className="text-cyan font-bold">{t("auth_title")}</span>
                </h2>
                <p className="op-5 mt-4">{t("auth_subtitle")}</p>
              </div>
            </Col>
            <Col md="6" lg="4" className="bg-white">
              <div className="p-4">
                <h3 className="font-medium mb-3">{t("auth_login")}</h3>
                <Form className="mt-3" id="loginform" action="/dashbaord">
                  <Label for="username" className="font-medium">
                    {t("auth_user")}
                  </Label>
                  <InputGroup className="mb-2" size="lg">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ti-user"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      id="username"
                      name="username"
                      onChange={(e) => {
                        setUsername(e.target.value);
                        onInputChange(e);
                      }}
                      placeholder={t("auth_user")}
                    />
                  </InputGroup>
                  {showErrors("username")}
                  {apiError && (
                    <div className="error mb-2">{t("auth_error")}</div>
                  )}
                  <Label for="password" className="mt-3 font-medium">
                    {t("auth_pass")}
                  </Label>
                  <InputGroup className="mb-3" size="lg">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ti-pencil"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      id="password"
                      name="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                        onInputChange(e);
                      }}
                      placeholder={t("auth_pass")}
                    />
                  </InputGroup>
                  {showErrors("password")}

                  <Row className="mb-3">
                    <Col xs="12">
                      <Button
                        color="primary"
                        onClick={(event) => doLogin(event)}
                        className={`${validForm() ? "" : "disabled"}`}
                        size="lg"
                        type="submit"
                        block
                      >
                        {t("auth_login_btn")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Login2;
