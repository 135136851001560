import React, { useEffect, useState } from "react";
import { Switch, Route, Router, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./redux/Store";
import { createBrowserHistory } from "history";
import Login2 from "./components/Login2";
import FullLayout from "./components/FullLayout";
import { useTranslation } from "react-i18next";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App(props) {
  const [history, setHistory] = useState(
    createBrowserHistory({ basename: "/" })
  );
  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "en");
    }
  }, []);

  return (
    <Provider store={configureStore()}>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
      />
      <Router history={history}>
        <Switch>
          <Route path="/auth">
            <Login2 t={t} />
          </Route>
          <PrivateRoute path="/">
            <FullLayout t={t} changeLanguage={changeLanguage} />
          </PrivateRoute>
        </Switch>
      </Router>
    </Provider>
  );
}

function PrivateRoute({ children, ...rest }) {
  let auth = localStorage.getItem("token");

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/auth",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
