import React, { useEffect, useLayoutEffect, useState, PureComponent, Component } from 'react';
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  FormGroup,
  Label,
  Button,
  Spinner,
} from 'reactstrap';
import { DateTime } from 'luxon';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ReactApexChart from 'react-apexcharts';
import { api } from '../api/api';
import StatisticCards from './StatisticCards';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, ComposedChart, Tooltip, Legend, ResponsiveContainer, Area } from 'recharts';
import { useSelector } from 'react-redux';


const Stat = ({ t }) => {
  const {statisticDict } = useSelector(state => state.dictionary)
  const [dataChart, setDataChart] = useState([])
  const [isChartLoading, setChartLoading] = useState(true)
  const [symbols, setSymbol] = useState([]);
  const [exchange, setExchange] = useState([]);
  const [strategys, setStrategys] = useState([]);
  const [userStatistic, setUserStatistic] = useState([]);
  const dateFrom = new Date();
  dateFrom.setDate(dateFrom.getDate() - 7);
  const dateTo = new Date();
  dateTo.setDate(dateTo.getDate());
  const [filter, setFilter] = useState({
    symbols: '',
    exchange: '',
    strategy: '',
    limit: null,
    from: Math.round(+dateFrom / 1000),
    to: Math.round(+dateTo / 1000),
  });

  useLayoutEffect(() => {
    api.getExchangeList().then(
      (res) => {
        if (res.status === 200) {
          setExchange(
            res.data.map((e, i) => {
              if (i === 0)
                setFilter((prev) => ({ ...prev, exchange: e.value }));
              return { value: e.key, label: e.value };
            })
          );
        }
      },
      (rej) => {}
    );
    api.getAssetList().then(
      (res) => {
        if (res.status === 200) {
          setSymbol(
            res.data.map((e, i) => {
              if (i === 0) setFilter((prev) => ({ ...prev, symbols: e.value }));
              return { value: e.key, label: e.value };
            })
          );
        }
      },
      (rej) => {}
    );
    api.getStrategiesDictionary().then(
      (res) => {
        if (res.status === 200) {
          setStrategys(
            res.data.map((e, i) => {
              if (i === 0) setFilter((prev) => ({ ...prev, strategy: e.key }));
              return { value: e.key, label: e.value };
            })
          );
        }
      },
      (rej) => {}
    );

    api.getUserStatistic(filter.from, filter.to).then(
      (res) => {
        if (res.status === 200) {
          setUserStatistic(res.data)
        }
      },
      (rej) => {}
    );
  }, []);

  const getChartData = async () => {
    setChartLoading(true)
    try {
      const response = await api.getSignals(
        filter.symbols,
        filter.exchange,
        filter.strategy,
        filter.from,
        filter.to,
        filter.limit
      )
      const values = [
        'value',
        'proba',
        'ma_proba',
        'bbl_proba',
        'bbu_proba',
        'perm',
      ];
      const newData = response.data.map(e => {
        const obj = {};
  
        values.map(k => {
          if (k === 'value' || k === 'proba') {
            obj[k] = e[k];
          } else {
            obj[k] = e.indicators[k];
          }
          obj.name = DateTime.fromMillis(e.time * 1000).toFormat(
            'yyyy-MM-dd HH:mm'
          );
        })
  
        return obj
      });
      setDataChart(newData)
      setChartLoading(false)
    } catch(e) {
      setDataChart([])
      setChartLoading(false)
    }

  }

  useEffect(() => {
    if (filter.symbols && filter.exchange && filter.strategy) {
      getChartData()
    }
  }, [filter]);
  

  return (
    exchange.length > 0 &&
    symbols.length > 0 &&
    strategys.length > 0 ? (
      <div>
        <Row>
          <Col sm={12} lg={12} className='statistic_selects'>
            <FormGroup>
              <Label for='exchange' style={{ fontSize: '18px' }}>
                {t('strategy_select_exchange')}
              </Label>
              <Select
                classNamePrefix='react_select'
                name='exchange'
                id='exchange'
                onChange={(e) => setFilter({ ...filter, exchange: e.value })}
                defaultValue={exchange[0]}
                options={exchange}
              />
            </FormGroup>
            <FormGroup>
              <Label for='symbol' style={{ fontSize: '18px' }}>
                {t('strategy_select_symbol')}
              </Label>
              <Select
                classNamePrefix='react_select'
                name='symbol'
                id='symbol'
                onChange={(e) => setFilter({ ...filter, symbols: e.value })}
                defaultValue={symbols[0]}
                options={symbols}
              />
            </FormGroup>
            <FormGroup>
              <Label for='strategy' style={{ fontSize: '18px' }}>
                {t('strategy_select_strategy')}
              </Label>
              <Select
                classNamePrefix='react_select'
                name='strategy'
                id='strategy'
                onChange={(e) => setFilter({ ...filter, strategy: e.value })}
                defaultValue={strategys[0]}
                options={strategys}
              />
            </FormGroup>


            <FormGroup>
              <Label for='from' style={{ fontSize: '18px' }}>
                {t('strategy_select_date_from')}
              </Label>
              <Datetime
                name='from'
                id='from'
                locale='en-gb'
                closeOnSelect
                onChange={(e) => {
                  const days30 = 2592000;
                  if (e._d) {
                    const time = +e._d / 1000;
                    if ((filter.to - time) > days30) {
                     setFilter({ ...filter, from: time, to: time + days30 });
                     return
                    }
                    setFilter({ ...filter, from: time });
                  }
                }}
                value={filter.from * 1000}
                inputProps={{ placeholder: t('main_dateph') }}
              />
            </FormGroup>

            <FormGroup>
              <Label for='to' style={{ fontSize: '18px' }}>
                {t('strategy_select_date_to')}
              </Label>
              <Datetime
                name='to'
                id='to'
                locale='en-gb'
                closeOnSelect
                onChange={(e) => {
                  const days30 = 2592000;
                  if (e._d){
                    const time = +e._d / 1000;
                    if((time - filter.from) > days30) {
                      setFilter({ ...filter, to: time, from: time - days30 });
                      return
                    }
                    setFilter({ ...filter, to: time });
                  }
                }}
                value={filter.to * 1000}
                inputProps={{ placeholder: t('main_dateph') }}
              />
            </FormGroup>
          </Col>
        </Row>
        {!isChartLoading ? !!dataChart.length ? (
          <div id='chart'>
            <Chart dataChart={dataChart} />
          </div>
        ) : <p>Chart: No Data</p> : <Spinner style={{display: 'flex', margin: '0 auto'}} />}
        <StatisticCards
          dictionary={statisticDict}
          className='statistic_cards'
          data={userStatistic}
        />
      </div>
    ) : <Spinner style={{display: 'flex', margin: '0 auto'}} />
  );
};

export default Stat;

const defaultLegendColors = {
  value: '#fff076',
  proba: '#f28787',
  ma_proba: '#aafb91',
  bbl_proba: '#90b8fa',
  bbu_proba: '#e590fa',
  perm: '#ec9400',
};

class Chart extends PureComponent {

  state = {
    opacity: {
      value: 1,
      proba: 1,
      ma_proba: 1,
      bbl_proba: 1,
      bbu_proba: 1,
      perm: 1,
    },
    legend: [
      {
        dataKey: 'value',
        color: '#fff076',
        value: 'Value',
      },
      {
        dataKey: 'proba',
        color: '#f28787',
        value: 'Proba'
      },
      {
        dataKey: 'ma_proba',
        color: '#aafb91',
        value: 'MA proba'
      },
      {
        dataKey: 'bbl_proba',
        color: '#90b8fa',
        value: 'BBL proba'
      },
      {
        dataKey: 'bbu_proba',
        color: '#e590fa',
        value: 'BBU proba'
      },
      {
        dataKey: 'perm',
        color: '#ec9400',
        value: 'Perm'
      }
    ]
  };

  handleMouseEnter = (o) => {
    const { dataKey } = o;
    const { opacity, legend } = this.state;

    const current = opacity[dataKey];

    const obj = {}

    for (let key in opacity) {
      if (opacity[key] === 0) {
        obj[key] = 0
      } else {
        obj[key] = 0.15
      }
    }

    this.setState({
      opacity: { ...obj, [dataKey]: current },
      legend
    });
  };

  handleMouseLeave = (o) => {
    const { opacity, legend } = this.state;
    const obj = {}
    for (let key in opacity) {
      if (opacity[key] === 0) {
        obj[key] = 0
      } else {
        obj[key] = 1
      }
    }
    this.setState({
      opacity: obj,
      legend
    });
  };

  handleClick = (o) => {
    const { dataKey } = o;
    const { opacity, legend } = this.state;
    const idx = legend.findIndex(e => e.dataKey === dataKey);
    const newLegend = [...legend];
    if (opacity[dataKey] > 0) {
      newLegend[idx].color = '#cdcdcd54';
      this.setState({opacity: { ...opacity, [dataKey]: 0 }, legend: [...newLegend]});
    } else {
      newLegend[idx].color = defaultLegendColors[dataKey];
      this.setState({opacity: { ...opacity, [dataKey]: 1 }, legend: [...newLegend]});
    }

  }

  render() {
    const { opacity, legend } = this.state;
    const interval = Math.round(this.props.dataChart.length / 45)

    return (
      <ResponsiveContainer width="100%" height={800}>
      <ComposedChart
        width={'100%'}
        height={700}
        data={this.props.dataChart}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="0 1" stroke="#cccccc59"/>
        <XAxis dataKey="name" height={120} interval={interval} tick={<CustomizedAxisTick />} />
        <YAxis />
        <Tooltip />
        <Legend 
          height={35} 
          verticalAlign="top" 
          onMouseEnter={this.handleMouseEnter} 
          onMouseLeave={this.handleMouseLeave} 
          onClick={this.handleClick}
          payload={legend}
        />
        <Area type="monotone" strokeOpacity={opacity.value} dataKey="value" fill="none" stroke="#fff076" />
        <Area type="monotone" strokeOpacity={opacity.proba} dataKey="proba" fill="none" stroke="#f28787" />
        <Area type="monotone" strokeOpacity={opacity.ma_proba} dataKey="ma_proba" fill="none" stroke="#aafb91" />
        <Area type="monotone" strokeOpacity={opacity.bbl_proba} dataKey="bbl_proba" fill="none" stroke="#90b8fa" />
        <Area type="monotone" strokeOpacity={opacity.bbu_proba} dataKey="bbu_proba" fill="none" stroke="#e590fa" />
        <Area type="monotone" strokeOpacity={opacity.perm} dataKey="perm" fill="none" stroke="#ec9400" />
      </ComposedChart>
    </ResponsiveContainer>
    );
  }
}

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-55)">
          {payload.value}
        </text>
      </g>
    );
  }
}