import axios from "axios";

export const api = {
  base: axios.create({
    baseURL: `/api/`,
  }),

  headers() {
    return {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };
  },

  auth(username, password) {
    const token = this.base
      .post("/auth", { username, password })
      .then((response) => {
        localStorage.setItem("token", JSON.stringify(response.data.token));
        localStorage.setItem("roles", JSON.stringify(response.data.roles));
        return response.data;
      });
    return token;
  },

  getAccountsList() {
    return this.base.get("/accounts", this.headers());
  },

  getAccountInfo(id) {
    return this.base.get(`/accounts/${id}`, this.headers());
  },

  getExchangeList() {
    return this.base.get("/dictionary/exchanges", this.headers());
  },

  getCurrencyList() {
    return this.base.get("/dictionary/assets", this.headers());
  },

  createAccount(name, exchange, key, secret, password, asset) {
    return this.base.post(
      "/accounts",
      { name, exchange, key, secret, password, asset },
      this.headers()
    );
  },

  updateAccount(id, key, secret, password) {
    return this.base.post(
      `/accounts/${id}/secure`,
      { key, secret, password },
      this.headers()
    );
  },

  renameAccount(id, name, asset) {
    return this.base.put(`/accounts/${id}`, { name, asset }, this.headers());
  },

  repayAccount(id) {
    return this.base.post(`/accounts/${id}/repay`, {},  this.headers());
  },

  deleteAccount(id) {
    return this.base.delete(`/accounts/${id}`, this.headers());
  },

  changeTrading(type, id) {
    return this.base.post(
      `/accounts/${id}/${type ? "enable" : "disable"}`,
      {},
      this.headers()
    );
  },

  addUser(username, password, roles) {
    return this.base.post(
      "/users",
      { username, password, roles },
      this.headers()
    );
  },

  deleteUser(id) {
    return this.base.delete(`/users/${id}`, this.headers());
  },

  getUsersList() {
    return this.base.get("/users", this.headers());
  },

  updateUserRole(id, roles) {
    return this.base.post(`users/${id}/promote`, { roles }, this.headers());
  },

  updateUserPass(id, password) {
    return this.base.post(`users/${id}/protect`, { password }, this.headers());
  },

  getAssetList() {
    return this.base.get("/dictionary/symbols", this.headers());
  },

  getAllDeals(id) {
    return this.base.get(`/deals`, this.headers());
  },

  getAccountDeals(account, asset, symbol, from, to, limit, page) {
    const query = ["?"];
    if (account) {
      query.push("account=" + account);
    }
    if (asset) {
      query.push("asset=" + asset);
    }
    if (symbol) {
      query.push("symbol=" + symbol);
    }
    if (from) {
      query.push("from=" + from);
    }
    if (to) {
      query.push("to=" + to);
    }
    if (limit) {
      query.push("limit=" + limit);
    }
    if (page) {
      query.push("page=" + page);
    }

    return this.base.get(
      `/deals${query.length > 1 ? query.join("&") : ""}`,
      this.headers()
    );
  },

  getDealDetails(dealId) {
    return this.base.get(`/deals/${dealId}`, this.headers());
  },

  closeDeal(dealId, amount) {
    return this.base.post(
      `/deals/${dealId}/leave`,
      { amount: +amount },
      this.headers()
    );
  },

  changePairsStatus(account, base, quote, status) {
    return this.base.post(
      `/accounts/${account}/pairs/${base}/${quote}/${
        status ? "enable" : "disable"
      }`,
      {},
      this.headers()
    );
  },

  getBalance(account, type) {
    return this.base.get(
      `/accounts/${account}/balance/${type ? "margin" : "spot"}`,
      this.headers()
    );
  },

  addPair(account, obj) {
    return this.base.post(`/accounts/${account}/pairs`, obj, this.headers());
  },

  changePairLimit(account, base, quote, obj) {
    return this.base.put(
      `/accounts/${account}/pairs/${base.toLowerCase()}/${quote.toLowerCase()}`,
      obj,
      this.headers()
    );
  },

  deletePair(account, base, quote) {
    return this.base.delete(
      `/accounts/${account}/pairs/${base}/${quote}`,
      this.headers()
    );
  },

  showPairData(account, base, qoute) {
    return this.base.get(`/accounts/${account}/pairs/${base}/${qoute}`, this.headers());
  },

  getStrategies(key) {
    return this.base.get(`/strategies/${key || ""}`, this.headers());
  },

  getStrategiesDictionary() {
    return this.base.get(`/dictionary/strategies`, this.headers());
  },

  getAccountsDictionary() {
    return this.base.get(`/dictionary/accounts`, this.headers());
  },

  addStrategy(obj) {
    return this.base.post(`/strategies`, obj, this.headers());
  },

  deleteStrategy(key) {
    return this.base.delete(`/strategies/${key}`, this.headers());
  },

  updateStrategy(key, obj) {
    return this.base.put(`/strategies/${key}`, obj, this.headers());
  },
  getSignals(symbol, exchange, strategy, from, to, limit) {
    return this.base.get(
      `/signals/${exchange.toLowerCase()}/${symbol.toLowerCase()}?source=${strategy.toLowerCase()}&precision=15&from=${from}&to=${to}${
        limit ? `&limit=${limit}` : ""
      }`,
      this.headers()
    );
  },
  getStatisticDictionary() {
    return this.base.get(`/dictionary/statistics`, this.headers());
  },

  getUserStatistic(from, to) {
    return this.base.get(`/stats?from=${from}&to=${to}`, this.headers());
  },

  getAccountStatistic(id) {
    return this.base.get(`/stats/${id}`, this.headers());
  },
  getMarginSymbols() {
    return this.base.get('/exchanges/binance/symbols/margin', this.headers())
  },

  getSpotSymbols() {
    return this.base.get('/exchanges/binance/symbols/spot', this.headers())
  },

  reloadBalance(id, options) {
    return this.base.post(`/accounts/${id}/balance/renew${options ? `?${Object.entries(options).map(e => e.join('=')).join('&')}`: ''}`, {}, this.headers())
  },

  getMarginHistory(id) {
    return this.base.get(`/accounts/${id}/transactions`, this.headers())
  },

  marginPush(id, asset, amount) {
    return this.base.post(`/accounts/${id}/transactions/${asset}/push`, { amount }, this.headers())
  },

  marginPull(id, asset, amount) {
    return this.base.post(`/accounts/${id}/transactions/${asset}/pull`, { amount }, this.headers())
  },

  marginBorrow(id, asset, amount) {
    return this.base.post(`/accounts/${id}/transactions/${asset}/borrow`, { amount }, this.headers())
  },

  marginRepay(id, asset, amount) {
    return this.base.post(`/accounts/${id}/transactions/${asset}/repay`, { amount }, this.headers())
  },

  getOrders(id) {
    return this.base.get(`/accounts/${id}/orders`, this.headers())
  },

  buySellOrder(id, obj) {
    return this.base.post(`/accounts/${id}/orders`, obj, this.headers())
  }
};
