import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css";
import CustomTable from "./CustomTable";

const CustomTableOnPage = ({
  title,
  onMainBtnClick,
  mainBtnText,
  tableHeader,
  tableBody,
  t
}) => {

  return (
    <Card>
      <CardTitle className="mb-0 p-3 border-bottom bg-light">
        <i className="mdi mdi-border-right mr-2"></i>
        {title}
      </CardTitle>
      {mainBtnText && <Button
        onClick={onMainBtnClick}
        color="success"
        size="sm"
        round="true"
        icon="true"
        style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
      >
        <i className="fas fa-plus-square" />
        <span style={{ paddingLeft: "10px" }}>{mainBtnText}</span>
      </Button>}
      <CardBody>
        <CustomTable
          t={t}
          header={tableHeader}
          body={tableBody}
        />
      </CardBody>
    </Card>
  )
}

export default CustomTableOnPage