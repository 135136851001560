import React, { useEffect, useState } from "react";
import { Table, Row, Col, Card, CardTitle, Button, Spinner } from "reactstrap";
import { api } from "../api/api";
import Select from "react-select";

const Balance = (props) => {
  const { t } = props;
  const [balanceType, setBalanceType] = useState(true);
  const [accountData, setAccountData] = useState([]);
  const [choosenAccount, setChoosenAccount] = useState(null);
  const [balance, setBalance] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    api.getAccountsDictionary().then(
      (res) => {
        if (res.status === 200) {
          const map = res.data.map((e) => ({ value: e.key, label: e.value }));
          setAccountData(map);
          setChoosenAccount(map[0].value)
        }
      },
      (rej) => {
        const status = rej.response.status;
        if (status === 401) {
          localStorage.clear();
          window.location.href = "/";
        } else if (status === 403) {
        }
      }
    );
  }, []);
  

  useEffect(() => {
    if (choosenAccount) {
      setLoading(true)
      api.getBalance(choosenAccount, balanceType).then(
        (res) => {
          if (res.status === 200) {
            setBalance(res.data);
            setLoading(false);
          }
        },
        (rej) => {
          const status = rej.response.status;
          if (status === 401) {
            localStorage.clear();
            window.location.href = "/";
          } else if (status === 403) {
          }
        }
      );
    }
  }, [balanceType, choosenAccount]);

  return (
    <>
      <Row
        style={{
          margin: "15px",
        }}
      >
        <h3
          style={{
            marginRight: "15px",
          }}
        >
          <strong>{t("balance_title")}</strong>
        </h3>
        {accountData.length && <div style={{ width: "250px" }}>
          <Select
            classNamePrefix="react_select"
            name="account"
            id="account"
            width="100%"
            placeholder={t("balance_choosePh")}
            onChange={(e) => setChoosenAccount(e.value)}
            options={accountData}
            defaultValue={accountData[0]}
          />
        </div>}
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardTitle
              className="mb-0 p-3 border-bottom bg-light"
              style={{ display: "flex"}}
            >
                <Button
                  color="none"
                  style={{
                    background: balanceType ? "#398bf7" : "#67757c",
                    marginRight: "10px",
                    color: "#fff",
                  }}
                  disabled={balanceType}
                  onClick={() => setBalanceType(true)}
                >
                  Margin
                </Button>
                <Button
                  color="none"
                  style={{
                    background: !balanceType ? "#398bf7" : "#67757c",
                    color: "#fff",
                  }}
                  disabled={!balanceType}
                  onClick={() => setBalanceType(false)}
                >
                  Spot
                </Button>
            </CardTitle>
            {!isLoading ? <Table responsive>
              <thead>
                <tr>
                  <th scope="col">{t("balance_table_name1")}</th>
                  <th scope="col">{t("balance_table_name2")}</th>
                  <th scope="col">{t("balance_table_name3")}</th>
                  <th scope="col">{t("balance_table_name5")}</th>
                  <th scope="col">{t("balance_table_name4")}</th>
                </tr>
              </thead>
              <tbody>
                 {balance.map((bal) => {
                    return (
                      <tr key={bal.key}>
                        <th scope="row">{bal.asset}</th>
                        <td>{bal.free}</td>
                        <td>{bal.used}</td>
                        <td>{bal.debt}</td>
                        <td>{bal.total}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table> : <Spinner style={{margin: '1rem auto'}} />}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Balance;
