import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroupAddon,
} from "reactstrap";
import Select from "react-select";
import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css";
import { api } from "../api/api";
import CustomTableOnPage from "./CustomTableOnPage";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";

const Orders = (props) => {
  const { t } = props;
  const { exchangeMargin, exchangeSpot } = useSelector(state => state.dictionary)
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [modalBuy, setModalBuy] = useState(false);
  const [choosenAccount, setChoosenAccount] = useState(null);
  const [accountData, setAccountData] = useState([]);

  const walletList = [
    {value: 'spot', label: 'Spot'}, 
    {value: 'margin', label: 'Margin'}
  ];

  const typesList = [
    {value: 'market', label: 'Market'}, 
    {value: 'limit', label: 'Limit'}
  ];

  const sideList = [
    {value: 'buy', label: 'Buy'}, 
    {value: 'sell', label: 'Sell'}
  ];
 
  const [currentWallet, setCurrentWallet] = useState(walletList[0].value)
  const [currentType, setCurrentType] = useState(typesList[0].value)
  const [currentSide, setCurrentSide] = useState(sideList[0].value)

  const symbolsList = useMemo(() => {
    if (currentWallet === 'margin') {
      return exchangeMargin.map(e => ({value: e, label: e}))
    } else {
      return exchangeSpot.map(e => ({value: e, label: e}))
    }
  }, [currentWallet, exchangeMargin, exchangeSpot])

  useEffect(() => {
    api.getAccountsDictionary().then(
      (res) => {
        if (res.status === 200) {
          setAccountData(
            res.data.map((e) => ({ value: e.key, label: e.value }))
          );
        }
      },
      (rej) => {
        const status = rej.response.status;
        if (status === 401) {
          localStorage.clear();
          window.location.href = "/";
        } else if (status === 403) {
        }
      }
    );
  }, []);

  useEffect(() => {
    if (choosenAccount) {
      api.getOrders(choosenAccount).then(
        (res) => {
          if (res.status === 200) {
            setData(res.data);
          }
        },
        (rej) => {
          const status = rej.response.status;
          if (status === 401) {
            localStorage.clear();
            window.location.href = "/";
          } else if (status === 403) {
          }
        }
      );
    } 
  }, [reload, choosenAccount]);

  const toggleBuy = () => {
    setModalBuy((modal) => !modal);
  };


  const handleSubmitBuy = (event) => {
    event.preventDefault();
    let amount = +event.target.amount.value;
    let reverse = event.target.reverse.checked;
    let asset = event.target.asset.value;
    let price = event.target.price ? +event.target.price.value : 0;

    const obj = {
      wallet: currentWallet,
      type: currentType,
      side: currentSide,
      amount,
      reverse,
      symbol: asset,
      price
    };

    api.buySellOrder(choosenAccount, obj).then(
      (res) => {
        if (res.status === 201) {
          setModalBuy(false);
          setReload((prev) => !prev);
        }
      },
      (rej) => {}
    );
  };


  const data2 = data.map((prop) => {
    return {
      id: <strong>{prop.id}</strong>,
      key: prop.id,
      number: prop.number || '-',
      wallet: prop.wallet,
      exchange: `${prop.market.exchange}`,
      symbol: `${prop.market.base}/${prop.market.quote}`,
      type: prop.type,
      side: prop.side,
      status: <p title={prop.reason || ''}>{prop.status}</p>,
      amount: `${prop.amount} / ${prop.filled}`,
      price: prop.price,
      time: DateTime.fromISO(`${prop.created}`).toFormat(
        "yyyy-MM-dd HH:mm"
      )
    };
  });

  return (
    <div>
      <Modal isOpen={modalBuy} toggle={toggleBuy.bind(null)}>
        <ModalHeader toggle={toggleBuy.bind(null)}>
          {t("orders_btn_buy")}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => handleSubmitBuy(event)}>
            <FormGroup>
              <Label for="wallet">{t("pair_modal_name2")}</Label>
              <Select
                type="textarea"
                name="wallet"
                id="wallet"
                defaultValue={walletList[0]}
                options={walletList}
                onChange={(e) => setCurrentWallet(e.value)}
                required
              />
            </FormGroup>
            <div style={{display: 'flex', position: 'relative'}}>
            <FormGroup>
              <Label for="type">{t("deal_table_type")}</Label>
              <InputGroupAddon addonType="prepend">
                {typesList.map(type => (
                  <Button
                    key={type.value}
                    style={{
                      background: currentType === type.value ? "#398bf7" : "#67757c",
                      border: "none",
                      zIndex: 0,
                    }}
                    className="btn btn-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentType(type.value)
                    }}
                  >
                  {type.label}
                </Button>
                ))}
              </InputGroupAddon>
            </FormGroup>
            {currentType === 'limit' && <FormGroup style={{marginLeft: '2rem'}}>
              <Label for="price"> {t("deal_table_price")}</Label>
              <Input
                type="text"
                name="price"
                id="price"
                defaultValue="0"
                required
              />
            </FormGroup>}
            </div>
            <FormGroup>
              <Label for="side">{t("orders_side")}</Label>
              <InputGroupAddon addonType="prepend">
                {sideList.map(side => (
                  <Button
                    key={side.value}
                    style={{
                      background: currentSide === side.value ? "#ddda57" : "#67757c",
                      color: currentSide === side.value ? "#000" : "#fff",
                      border: "none",
                      zIndex: 0,
                    }}
                    className="btn btn-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentSide(side.value)
                    }}
                  >
                  {side.label}
                </Button>
                ))}
              </InputGroupAddon>
            </FormGroup>
            <FormGroup>
              <Label for="amount"> {t("deal_table_amount")}</Label>
              <Input
                type="text"
                name="amount"
                id="amount"
                required
              />
            </FormGroup>
            <FormGroup style={{
              display: 'flex'
            }}>
              <Label for="reverse" style={{marginLeft: '2rem'}}> {t("orders_reverse")}</Label>
              <Input
                style={{width: '20px', height: '20px', marginLeft: 0, marginTop: 0}}
                type="checkbox"
                name="reverse"
                id="reverse"
              />
            </FormGroup>
            <FormGroup>
              <Label for="asset">{t("account_modal_add_name3")}</Label>
              <Select
                type="textarea"
                name="asset"
                id="asset"
                options={symbolsList}
                required
              />
            </FormGroup>
            <FormGroup>
              <Button color="primary" type="submit">
                {t("settings_btn")}
              </Button>
              <Button
                color="secondary"
                className="ml-1"
                onClick={toggleBuy.bind(null)}
              >
                {t("account_modal_add_btnCncl")}
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      
      <div style={{display: 'flex', padding: '0 0 20px'}}>
        <div style={{ width: "250px" }}>
          <Select
            classNamePrefix="react_select"
            name="account"
            id="account"
            width="100%"
            placeholder={t("balance_choosePh")}
            onChange={(e) => setChoosenAccount(e.value)}
            options={accountData}
          />
        </div>
        <Button
          color="secondary"
          className="ml-3"
          disabled={!choosenAccount}
          onClick={(toggleBuy.bind(null))}
        >
          {t('orders_btn_buy')}
        </Button>
      </div>
      
      <CustomTableOnPage 
        t={t}
        title={t("routes_name_orders")}
        tableHeader={[
          {
            Header: t("main_table_id"),
            accessor: "id",
            style: {
              width: '200px'
            }
          },
          {
            Header: t("margin_number"),
            accessor: "number",
          },
          {
            Header: t("pair_modal_name2"),
            accessor: "wallet",
          },
          {
            Header: t("accounts_head_2"),
            accessor: "exchange",
          },
          {
            Header: t("strategy_select_symbol"),
            accessor: "symbol",
          },
          {
            Header: t("deal_table_type"),
            accessor: "type",
          },
          {
            Header: t("orders_side"),
            accessor: "side",
          },
          {
            Header: t("deal_table_status"),
            accessor: "status",
          },
          {
            Header: `${t("deal_table_amount")} / ${t('orders_filled')}`,
            accessor: "amount",
          },
          {
            Header: t("deal_table_price"),
            accessor: "price",
          },
          {
            Header: t("margin_time"),
            accessor: "time",
          },
        ]}
        tableBody={data2}
      />

    </div>
  );
};

export default Orders;
