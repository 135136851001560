import React from "react";

const Footer = () => {
  return (
    <footer className="footer text-center">
      All Rights Reserved by DataVisio.
    </footer>
  );
};
export default Footer;
