import React, { useEffect, useState } from 'react';
import { Card, CardBody } from "reactstrap";
import img1 from "../assets/images/icons/deals.png";

const StatisticCards = ({
  data,
  dictionary,
  className = ''
}) => {

  return <div className={className} >
    {data && data.map(e => (
      <Card style={{marginBottom: 0}} key={e.key}>
      <span className="lstick widget-card bg-info"></span>
      <CardBody>
        <div className="d-flex">
          {/* <div className="mr-3 align-self-center">
            <img src={img1} alt="income" width="50" height="50" />
          </div> */}
          <div className="align-self-center">
            <h6 className="text-muted mt-2 mb-0">{dictionary[e.key] ? dictionary[e.key] : e.key}</h6>
            <h2 className="mt-0 ">{e.value}</h2>
          </div>
        </div>
      </CardBody>
    </Card>
    )
    )}
  </div>
}

export default StatisticCards