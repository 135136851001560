import {
  EXCHANGE_MARGIN,
  EXCHANGE_SPOT,
  ASSETS,
  SYMBOLS,
  STATISTIC_DICT
} from "../constants";

export const setExchangeMargin = (payload) => {
  return {
    type: EXCHANGE_MARGIN,
    payload,
  };
};

export const setExchangeSpot = (payload) => {
  return {
    type: EXCHANGE_SPOT,
    payload,
  };
};

export const setAssets = (payload) => {
  return {
    type: ASSETS,
    payload,
  };
};

export const setSymbols= (payload) => {
  return {
    type: SYMBOLS,
    payload,
  };
};

export const setStatisticDictionary= (payload) => {
  return {
    type: STATISTIC_DICT,
    payload,
  };
};