import React, { useEffect, useState } from "react";
import { Row, Col, Button, FormGroup, Label, Input } from "reactstrap";
import { api } from "../api/api";
import Switch from "react-bootstrap-switch";
import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css";
import { useDispatch } from "react-redux";
import {
  setTheme,
  setLogoBg,
  setNavbarBg,
  setSidebarBg,
} from "../redux/settings/Action";

const AccountSettings = (props) => {
  const { t } = props;
  const [pass, setPass] = useState(null);
  const [user, setUser] = useState(null);
  const [newPass, setNewPass] = useState({ new: "", check: "" });
  const [themes, setThemes] = useState(localStorage.getItem("theme"));
  const dispatch = useDispatch();

  useEffect(() => {
    api.getUsersList().then((res) => {
      if (res.status === 200) {
        setUser(
          res.data.filter(
            (e) => e.username === localStorage.getItem("login")
          )[0]
        );
      }
    });
  }, []);

  const changeTheme = () => {
    if (themes === "dark") {
      setThemes("light");
      localStorage.setItem("theme", "light");
      dispatch(setTheme("light"));
      dispatch(setLogoBg("skin6"));
      dispatch(setNavbarBg("skin6"));
      dispatch(setSidebarBg("skin6"));
    } else {
      setThemes("dark");
      localStorage.setItem("theme", "dark");
      dispatch(setTheme("dark"));
      dispatch(setLogoBg("skin5"));
      dispatch(setNavbarBg("skin5"));
      dispatch(setSidebarBg("skin5"));
    }
  };

  const changePass = () => {
    if (newPass.new === newPass.check) {
      api.updateUserPass(user.id, newPass.new).then(
        (res) => {
          if (res.status === 202) {
            setPass({ text: t("settings_pass_ok"), color: "#07df07" });
            setNewPass({ new: "", check: "" });
            setTimeout(() => setPass(null), 1500);
          }
        },
        (rej) => {
          setPass({ text: t("settings_error"), color: "#d52426" });
        }
      );
    } else if (newPass.new !== "" && newPass.check !== "") {
      setPass({ text: t("settings_pass_err"), color: "#d52426" });
      setTimeout(() => setPass(null), 1500);
    }
  };

  return (
    <div>
      <Row style={{ marginBottom: "20px" }}>
        <Col sm="12" md={{ size: 6, offset: 0 }}>
          <h2 style={{ fontWeight: "bold" }}>
            {t("settings_title")} {user ? user.username : ""}
          </h2>
        </Col>
      </Row>

      <Row>
        <Col sm="12" md={{ size: 4, offset: 1 }}>
          <FormGroup>
            <Label for="name">{t("settings_name1")}</Label>
            <Input
              type="password"
              name="name"
              id="name"
              value={newPass.new}
              onChange={(e) => setNewPass({ ...newPass, new: e.target.value })}
            />
            <Label for="name" style={{ marginTop: "10px" }}>
              {t("settings_name2")}
            </Label>
            <Input
              type="password"
              name="name"
              id="name"
              value={newPass.check}
              onChange={(e) =>
                setNewPass({ ...newPass, check: e.target.value })
              }
            />
            <Button
              style={{ marginTop: "10px" }}
              onClick={changePass}
              color="primary"
            >
              {t("settings_btn")}
            </Button>
            {pass && (
              <span style={{ marginLeft: "20px", color: pass.color }}>
                {pass.text}
              </span>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md={{ size: 6, offset: 1 }}>
          <span>{t("settings_name3")}</span>
          <div style={{ marginTop: "10px" }}>
            <Switch
              name="theme"
              id="theme"
              onColor="default"
              offColor="info"
              onText="Dark"
              offText="Light"
              defaultValue={themes === "dark"}
              onChange={changeTheme}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AccountSettings;
