import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Table,
  InputGroupAddon,
  InputGroup,
} from "reactstrap";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css";
import { api } from "../api/api";
import ModalRequest from "./ModalRequest";
import CustomTable from "./CustomTable";
import { useHistory } from "react-router-dom";

const Strategies = (props) => {
  const { t } = props;
  const objInitState = {
    id: "",
    name: { props: { children: "" } },
    exchange: "",
    trade: true,
  };
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [modal, setModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [requestAction, setRequsetAction] = useState(null);
  const [obj, setObj] = useState(objInitState);

  useEffect(() => {
    api.getStrategies().then(
      (res) => {
        if (res.status === 200) {
          setData(res.data);
        }
      },
      (rej) => {
        const status = rej.response.status;
        if (status === 401) {
          localStorage.clear();
          window.location.href = "/";
        } else if (status === 403) {
        }
      }
    );
  }, [reload]);

  const toggle = () => {
    setModal((modal) => !modal);
  };

  const toggleAdd = () => {
    setAddModal((modal) => !modal);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let name = event.target.name.value;
    let description = event.target.description.value;
    let version = event.target.version.value;

    api.updateStrategy(obj.key, { name, description, version }).then(
      (res) => {
        if (res.status === 202) {
          setModal(!modal);
          setReload((prev) => !prev);
        }
      },
      (rej) => {}
    );
  };

  const handleAddSubmit = (event) => {
    event.preventDefault();
    let name = event.target.name.value;
    let key = event.target.key.value;
    let description = event.target.description.value;
    let version = event.target.version.value;

    api.addStrategy({ key, name, description, version }).then(
      (res) => {
        if (res.status === 201) {
          setAddModal((prev) => !prev);
          setReload((prev) => !prev);
        }
      },
      (rej) => {}
    );
  };

  const data2 = data.map((prop) => {
    return {
      name: <strong>{prop.name}</strong>,
      key: prop.key,
      version: prop.version,
      updated: prop.updated.split(/\.|\T/g).slice(0, 2).join(" "),
      actions: (
        <div>
          <Button
            onClick={() => {
              let obj = data2.find((o) => o.key === prop.key);
              setModal(!modal);
              setObj(obj);
            }}
            color="primary"
            size="sm"
            round="true"
            icon="true"
          >
            <i className="fa fa-edit" />
          </Button>
          <Button
            onClick={() => {
              setRequsetAction(prop);
              setObj(obj);
            }}
            className="ml-1"
            color="danger"
            size="sm"
            round="true"
            icon="true"
          >
            <i className="fa fa-times" />
          </Button>
        </div>
      ),
    };
  });

  const deleteAccount = (obj) => {
    api.deleteStrategy(obj.key).then(
      (res) => {
        if (res.status === 202) {
          setReload((prev) => !prev);
        }
      },
      (rej) => {}
    );
  };

  return (
    <div>
      {requestAction && (
        <ModalRequest
          t={t}
          modalText={t("strategy_modal_request")}
          onCancel={() => setRequsetAction(null)}
          onSubmit={deleteAccount}
          obj={requestAction}
        />
      )}
      <Modal isOpen={modal} toggle={toggle.bind(null)}>
        <ModalHeader toggle={toggle.bind(null)}>
          {t("strateg_edit_title")}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => handleSubmit(event)}>
            <FormGroup>
              <Label for="name"> {t("account_modal_add_name1")}</Label>
              <Input
                type="text"
                name="name"
                id="name"
                required
                defaultValue={obj.name.props.children}
              />
            </FormGroup>

            <FormGroup>
              <Label for="description">{t("strategy_modal_add_name1")}</Label>
              <Input
                type="textarea"
                name="description"
                id="description"
                required
                defaultValue={obj.description}
              />
            </FormGroup>
            <FormGroup>
              <Label for="version">{t("strategy_modal_add_name2")}</Label>
              <Input
                type="text"
                name="version"
                id="version"
                required
                defaultValue={obj.version}
              />
            </FormGroup>
            <FormGroup>
              <Button color="primary" type="submit">
                {t("account_modal_edit_btnOk")}
              </Button>
              <Button
                color="secondary"
                className="ml-1"
                onClick={toggle.bind(null)}
              >
                {t("account_modal_add_btnCncl")}
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={addModal} toggle={toggleAdd.bind(null)}>
        <ModalHeader toggle={toggleAdd.bind(null)}>
          {t("strateg_add_title")}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => handleAddSubmit(event)}>
            <FormGroup>
              <Label for="name"> {t("account_modal_add_name1")}</Label>
              <Input type="text" name="name" id="name" required />
            </FormGroup>
            <FormGroup>
              <Label for="key">{t("account_modal_add_name4")}</Label>
              <Input type="text" name="key" id="key" required />
            </FormGroup>
            <FormGroup>
              <Label for="description">{t("strategy_modal_add_name1")}</Label>
              <Input
                type="textarea"
                name="description"
                id="description"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="version">{t("strategy_modal_add_name2")}</Label>
              <Input type="text" name="version" id="version" required />
            </FormGroup>

            <FormGroup>
              <Button color="primary" type="submit">
                {t("account_modal_add_btnOk")}
              </Button>
              <Button
                color="secondary"
                className="ml-1"
                onClick={toggleAdd.bind(null)}
              >
                {t("account_modal_add_btnCncl")}
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      <Card>
        <CardTitle className="mb-0 p-3 border-bottom bg-light">
          <i className="mdi mdi-border-right mr-2"></i>
          {t("strategy_title")}
        </CardTitle>
        <Button
          onClick={() => {
            setAddModal((prev) => !prev);
            setObj(objInitState);
          }}
          color="success"
          size="sm"
          round="true"
          icon="true"
          style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
        >
          <i className="fas fa-plus-square" />
          <span style={{ paddingLeft: "10px" }}>{t("strategy_add")}</span>
        </Button>
        <CardBody>
          <CustomTable
            t={t}
            header={[
              {
                Header: t("strategy_head_1"),
                accessor: "name",
              },
              {
                Header: t("strategy_head_2"),
                accessor: "key",
              },
              {
                Header: t("strategy_head_3"),
                accessor: "version",
              },
              {
                Header: t("strategy_head_4"),
                accessor: "updated",
              },
              {
                Header: t("strategy_head_5"),
                accessor: "actions",
              },
            ]}
            body={data2}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default Strategies;
