import React, { useState, useEffect, useLayoutEffect, Suspense, useMemo } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import Header from "./layout-components/header/Header";
import Sidebar from "./layout-components/sidebar/Sidebar";
import Footer from "./layout-components/footer/Footer";
import Spinner from "./spinner/Spinner";
import ThemeRoutes from "./Routes";
import { DealDetails } from "./MainPage";
import Balance from "./Balance";
import AccountSettings from "./AccountSettings";
import { useDispatch, useSelector } from "react-redux";
import { PairDetails } from "./AccountList";
import UsersTable from "./UserManage";
import AccountList from "./AccountList";
import MainPage from "./MainPage";
import Statistic from "./Statistic";
import Strategies from "./Strategies";
import { api } from "../api/api";
import { setAssets, setExchangeMargin, setExchangeSpot, setStatisticDictionary, setSymbols } from "../redux/dictionary/Action";
import Margin from "./Margin";
import Orders from "./Orders";

const FullLayout = (props) => {
  const { t, changeLanguage } = props;
  const [width, setWidth] = useState(window.innerWidth);
  const { settings } = useSelector((state) => state);
  const history = useHistory();
  const roles = localStorage.getItem("roles");
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", "dark");
    }
  }, []);

  const getState = async () => {
    const assetsData = await api.getCurrencyList()
    dispatch(setAssets(assetsData.data))
    const symbolsData = await api.getAssetList()
    dispatch(setSymbols(symbolsData.data))
    const statisticDict = await api.getStatisticDictionary()
    const statDictObj = {}
    statisticDict.data.map(e => {
      statDictObj[e.key] = e.value;
    })
    dispatch(setStatisticDictionary(statDictObj))
    const marginData = await api.getMarginSymbols()
    dispatch(setExchangeMargin(marginData.data));
    const spotData = await api.getSpotSymbols()
    dispatch(setExchangeSpot(spotData.data));
  }

  useLayoutEffect(() => {
    getState()
  }, []);


  useEffect(() => {
    const updateDimensions = () => {
      let element = document.getElementById("main-wrapper");
      setWidth(window.innerWidth);
      switch (settings.activeSidebarType) {
        case "full":
        case "iconbar":
          if (width < 1170) {
            element.setAttribute("data-sidebartype", "mini-sidebar");
            element.classList.add("mini-sidebar");
          } else {
            element.setAttribute(
              "data-sidebartype",
              settings.activeSidebarType
            );
            element.classList.remove("mini-sidebar");
          }
          break;

        case "overlay":
          if (width < 767) {
            element.setAttribute("data-sidebartype", "mini-sidebar");
          } else {
            element.setAttribute(
              "data-sidebartype",
              settings.activeSidebarType
            );
          }
          break;

        default:
      }
    };
    if (document.readyState === "complete") {
      updateDimensions();
    }
    window.addEventListener("load", updateDimensions.bind(null));
    window.addEventListener("resize", updateDimensions.bind(null));
    return () => {
      window.removeEventListener("load", updateDimensions.bind(null));
      window.removeEventListener("resize", updateDimensions.bind(null));
    };
  }, [settings.activeSidebarType, width]);


  const getAdminRoutes = useMemo(() => {
    if (roles && roles.includes("admin")) {
      return <>
      <Route history={history} path="/users">
        <UsersTable t={t} />
      </Route>
      <Route history={history} path="/strategies">
        <Strategies t={t} />
      </Route>
    </>
    }
  }, [roles])

  return (
    <div
      id="main-wrapper"
      dir={settings.activeDir}
      data-theme={settings.activeTheme}
      data-layout={settings.activeThemeLayout}
      data-sidebartype={settings.activeSidebarType}
      data-sidebar-position={settings.activeSidebarPos}
      data-header-position={settings.activeHeaderPos}
      data-boxed-layout={settings.activeLayout}
    >
      {/*--------------------------------------------------------------------------------*/}
      {/* Header                                                                         */}
      {/*--------------------------------------------------------------------------------*/}
      <Header t={t} changeLanguage={changeLanguage} />
      {/*--------------------------------------------------------------------------------*/}
      {/* Sidebar                                                                        */}
      {/*--------------------------------------------------------------------------------*/}
      <Sidebar {...props} routes={ThemeRoutes} roles={roles} t={t} />
      {/*--------------------------------------------------------------------------------*/}
      {/* Page Main-Content                                                              */}
      {/*--------------------------------------------------------------------------------*/}
      <div className="page-wrapper d-block">
        <div className="page-content container-fluid">
          {history.location.pathname === "/" && (
            <Redirect
              to={{
                pathname: "/main",
                state: { from: history.location },
              }}
            />
          )}
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Route history={history} path="/deal">
                <DealDetails t={t} />
              </Route>
              <Route history={history} path="/balance">
                <Balance t={t} />
              </Route>
              <Route history={history} path="/settings">
                <AccountSettings t={t} />
              </Route>
              <Route history={history} path="/main">
                <MainPage t={t} />
              </Route>
              <Route history={history} path="/accounts">
                <AccountList t={t} />
              </Route>
              <Route history={history} path="/statistic">
                <Statistic t={t} />
              </Route>
              <Route history={history} path="/margin">
                <Margin t={t} />
              </Route>
              <Route history={history} path="/orders">
                <Orders t={t} />
              </Route>
              {getAdminRoutes}
              {/* {ThemeRoutes.map((prop, key) => {
                if (!roles.includes(prop.role)) {
                  return;
                }
                if (prop.navlabel) {
                  return null;
                } else if (prop.collapse) {
                  return prop.child.map((prop2, key2) => {
                    if (prop2.collapse) {
                      return prop2.subchild.map((prop3, key3) => {
                        return (
                          <Route history={history} path={prop3.path} key={key3}>
                            <prop3.component t={t} />
                          </Route>
                        );
                      });
                    }
                    return (
                      <Route history={history} path={prop2.path} key={key2}>
                        <prop2.component t={t} />
                      </Route>
                    );
                  });
                } else if (prop.redirect) {
                  return (
                    <Redirect from={prop.path} to={prop.pathTo} key={key} />
                  );
                } else {
                  return (
                    <Route history={history} path={prop.path} key={key}>
                      <prop.component t={t} />
                    </Route>
                  );
                }
              })} */}
            </Switch>
          </Suspense>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default FullLayout;
