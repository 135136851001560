import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css";
import { api } from "../api/api";
import CustomTableOnPage from "./CustomTableOnPage";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";

const Margin = (props) => {
  const { t } = props;
  const { assets } = useSelector(state => state.dictionary)
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [modalPush, setModalPush] = useState(false);
  const [modalPull, setModalPull] = useState(false);
  const [modalBorrow, setModalBorrow] = useState(false);
  const [modalRepay, setModalRepay] = useState(false);
  const [choosenAccount, setChoosenAccount] = useState(null);
  const [accountData, setAccountData] = useState([]);

  const assetsList = useMemo(() => {
    if (assets) {
      return assets.map(e => ({value: e.value, label: e.key}))
    }
    return []
  }, [assets])

  useEffect(() => {
    api.getAccountsDictionary().then(
      (res) => {
        if (res.status === 200) {
          setAccountData(
            res.data.map((e) => ({ value: e.key, label: e.value }))
          );
        }
      },
      (rej) => {
        const status = rej.response.status;
        if (status === 401) {
          localStorage.clear();
          window.location.href = "/";
        } else if (status === 403) {
        }
      }
    );
  }, []);

  useEffect(() => {
    if (choosenAccount) {
      api.getMarginHistory(choosenAccount).then(
        (res) => {
          if (res.status === 200) {
            setData(res.data);
          }
        },
        (rej) => {
          const status = rej.response.status;
          if (status === 401) {
            localStorage.clear();
            window.location.href = "/";
          } else if (status === 403) {
          }
        }
      );
    } 
  }, [reload, choosenAccount]);

  const togglePush = () => {
    setModalPush((modal) => !modal);
  };

  const togglePull = () => {
    setModalPull((modal) => !modal);
  };

  const toggleBorrow = () => {
    setModalBorrow((modal) => !modal);
  };

  const toggleRepay = () => {
    setModalRepay((modal) => !modal);
  };

  const handleSubmitPush = (event) => {
    event.preventDefault();
    let amount = event.target.amount.value;
    let asset = event.target.asset.value;

    api.marginPush(choosenAccount, asset, amount).then(
      (res) => {
        if (res.status === 202) {
          setModalPush(false);
          setReload((prev) => !prev);
        }
      },
      (rej) => {}
    );
  };

  const handleSubmitPull = (event) => {
    event.preventDefault();
    let amount = event.target.amount.value;
    let asset = event.target.asset.value;

    api.marginPull(choosenAccount, asset, amount).then(
      (res) => {
        if (res.status === 202) {
          setModalPull(false);
          setReload((prev) => !prev);
        }
      },
      (rej) => {}
    );
  };

  const handleSubmitBorrow = (event) => {
    event.preventDefault();
    let amount = event.target.amount.value;
    let asset = event.target.asset.value;

    api.marginBorrow(choosenAccount, asset, amount).then(
      (res) => {
        if (res.status === 202) {
          setModalBorrow(false);
          setReload((prev) => !prev);
        }
      },
      (rej) => {}
    );
  };

  const handleSubmitRepay = (event) => {
    event.preventDefault();
    let amount = event.target.amount.value;
    let asset = event.target.asset.value;

    api.marginRepay(choosenAccount, asset, amount).then(
      (res) => {
        if (res.status === 202) {
          setModalRepay(false);
          setReload((prev) => !prev);
        }
      },
      (rej) => {}
    );
  };

  const data2 = data.map((prop) => {
    const index = accountData.findIndex(e => e.value === prop.account);
    const accountName = index !== -1 ? accountData[index].label : prop.account;

    return {
      id: <strong>{prop.id}</strong>,
      key: prop.id,
      account: accountName,
      wallet: prop.wallet,
      operation: prop.operation,
      amount: `${prop.amount.value}${prop.amount.asset}`,
      success: <p style={prop.status === 'Failed' ? {color: '#ef7777'} : {}} title={prop.reason || ''}>{prop.status}</p>,
      number: prop.number || '-',
      time: DateTime.fromISO(`${prop.updated}Z`).toFormat(
        "yyyy-MM-dd HH:mm"
      )
    };
  });

  return (
    <div>
      <Modal isOpen={modalPush} toggle={togglePush.bind(null)}>
        <ModalHeader toggle={togglePush.bind(null)}>
          {t("margin_push")}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => handleSubmitPush(event)}>
            <FormGroup>
              <Label for="amount"> {t("deal_table_amount")}</Label>
              <Input
                type="text"
                name="amount"
                id="amount"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="asset">{t("account_modal_add_name3")}</Label>
              <Select
                type="textarea"
                name="asset"
                id="asset"
                defaultValue={assetsList[0]}
                options={assetsList}
                required
              />
            </FormGroup>
            <FormGroup>
              <Button color="primary" type="submit">
                {t("settings_btn")}
              </Button>
              <Button
                color="secondary"
                className="ml-1"
                onClick={togglePush.bind(null)}
              >
                {t("account_modal_add_btnCncl")}
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalPull} toggle={togglePull.bind(null)}>
        <ModalHeader toggle={togglePull.bind(null)}>
          {t("margin_pull")}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => handleSubmitPull(event)}>
            <FormGroup>
              <Label for="amount"> {t("deal_table_amount")}</Label>
              <Input
                type="text"
                name="amount"
                id="amount"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="asset">{t("account_modal_add_name3")}</Label>
              <Select
                type="textarea"
                name="asset"
                id="asset"
                defaultValue={assetsList[0]}
                options={assetsList}
                required
              />
            </FormGroup>
            <FormGroup>
              <Button color="primary" type="submit">
                {t("settings_btn")}
              </Button>
              <Button
                color="secondary"
                className="ml-1"
                onClick={togglePull.bind(null)}
              >
                {t("account_modal_add_btnCncl")}
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalBorrow} toggle={toggleBorrow.bind(null)}>
        <ModalHeader toggle={toggleBorrow.bind(null)}>
          {t("margin_borrow")}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => handleSubmitBorrow(event)}>
            <FormGroup>
              <Label for="amount"> {t("deal_table_amount")}</Label>
              <Input
                type="text"
                name="amount"
                id="amount"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="asset">{t("account_modal_add_name3")}</Label>
              <Select
                type="textarea"
                name="asset"
                id="asset"
                options={assetsList}
                defaultValue={assetsList[0]}
                required
              />
            </FormGroup>
            <FormGroup>
              <Button color="primary" type="submit">
                {t("settings_btn")}
              </Button>
              <Button
                color="secondary"
                className="ml-1"
                onClick={toggleBorrow.bind(null)}
              >
                {t("account_modal_add_btnCncl")}
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalRepay} toggle={toggleRepay.bind(null)}>
        <ModalHeader toggle={toggleRepay.bind(null)}>
          {t("margin_repay")}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => handleSubmitRepay(event)}>
            <FormGroup>
              <Label for="amount"> {t("deal_table_amount")}</Label>
              <Input
                type="text"
                name="amount"
                id="amount"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="asset">{t("account_modal_add_name3")}</Label>
              <Select
                type="textarea"
                name="asset"
                id="asset"
                options={assetsList}
                defaultValue={assetsList[0]}
                required
              />
            </FormGroup>
            <FormGroup>
              <Button color="primary" type="submit">
                {t("settings_btn")}
              </Button>
              <Button
                color="secondary"
                className="ml-1"
                onClick={toggleRepay.bind(null)}
              >
                {t("account_modal_add_btnCncl")}
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      
      <div style={{display: 'flex', padding: '0 0 20px'}}>
        <div style={{ width: "250px" }}>
          <Select
            classNamePrefix="react_select"
            name="account"
            id="account"
            width="100%"
            placeholder={t("balance_choosePh")}
            onChange={(e) => setChoosenAccount(e.value)}
            options={accountData}
          />
        </div>
        <Button
          color="secondary"
          className="ml-3"
          disabled={!choosenAccount}
          onClick={(togglePush.bind(null))}
        >
          {t('margin_push')}
        </Button>
        <Button
          color="secondary"
          className="ml-3"
          disabled={!choosenAccount}
          onClick={(togglePull.bind(null))}
        >
          {t('margin_pull')}
        </Button>
        <Button
          color="secondary"
          className="ml-3"
          disabled={!choosenAccount}
          onClick={(toggleBorrow.bind(null))}
        >
          {t('margin_borrow')}
        </Button>
        <Button
          color="secondary"
          className="ml-3"
          disabled={!choosenAccount}
          onClick={(toggleRepay.bind(null))}
        >
          {t('margin_repay')}
        </Button>
      </div>
      
      <CustomTableOnPage 
        t={t}
        title={t("margin_title")}
        tableHeader={[
          {
            Header: t("main_table_id"),
            accessor: "id",
            style: {
              width: '200px'
            }
          },
          {
            Header: t("margin_number"),
            accessor: "number",
          },
          {
            Header: t("pair_modal_name2"),
            accessor: "wallet",
          },
          {
            Header: t("margin_operation"),
            accessor: "operation",
          },
          {
            Header: t("deal_table_amount"),
            accessor: "amount",
          },
          {
            Header: t("margin_success"),
            accessor: "success",
          },
          {
            Header: t("margin_time"),
            accessor: "time",
          },
        ]}
        tableBody={data2}
      />

    </div>
  );
};

export default Margin;
