import { combineReducers } from "redux";
import settings from "./settings/Reducer";
import dictionary from './dictionary/Reducer';

const Reducers = combineReducers({
  settings,
  dictionary
});

export default Reducers;
