import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
} from "reactstrap";

const ModalRequest = (props) => {
  const { modalText, onSubmit, onCancel, obj, t } = props;

  return (
    <Modal isOpen toggle={onCancel}>
      <ModalHeader toggle={onCancel}>
        {t("submit_title")} "{modalText}"
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={() => {
            onSubmit(obj);
            onCancel();
          }}
        >
          <FormGroup>
            <Button color="primary" type="submit">
              {t("submit_btnOk")}
            </Button>
            <Button color="secondary" className="ml-1" onClick={onCancel}>
              {t("submit_btnCnlc")}
            </Button>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalRequest;
