import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import logodark from "../../../assets/images/logo.png";
import logolight from "../../../assets/images/logodark.png";
import logomini from "../../../assets/images/logo-mini.png";
import profilephoto from "../../../assets/images/profilephoto.jpg";

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [lang, setLang] = useState(null);
  const { t, changeLanguage } = props;
  const settings = useSelector((state) => state.settings);

  const history = useHistory();

  useEffect(() => {
    setLang(localStorage.getItem("lang"));
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const searchtoggle = () => {
    setCollapse(!collapse);
  };

  const showMobilemenu = () => {
    document.getElementById("main-wrapper").classList.toggle("show-sidebar");
  };

  const sidebarHandler = () => {
    let element = document.getElementById("main-wrapper");
    switch (settings.activeSidebarType) {
      case "full":
      case "iconbar":
        element.classList.toggle("mini-sidebar");
        if (element.classList.contains("mini-sidebar")) {
          element.setAttribute("data-sidebartype", "mini-sidebar");
        } else {
          element.setAttribute("data-sidebartype", settings.activeSidebarType);
        }
        break;

      case "overlay":
      case "mini-sidebar":
        element.classList.toggle("full");
        if (element.classList.contains("full")) {
          element.setAttribute("data-sidebartype", "full");
        } else {
          element.setAttribute("data-sidebartype", settings.activeSidebarType);
        }
        break;
      default:
    }
  };

  const changeLang = (ln) => {
    if (ln !== lang) {
      setLang(ln);
      localStorage.setItem("lang", ln);
      changeLanguage(ln);
    }
  };

  return (
    <header className="topbar navbarbg" data-navbarbg={settings.activeNavbarBg}>
      <Navbar
        className={
          "top-navbar " +
          (settings.activeNavbarBg === "skin6" ? "navbar-light" : "navbar-dark")
        }
        expand="md"
      >
        <div
          className="navbar-header"
          id="logobg"
          data-logobg={settings.activeLogoBg}
        >
          {/*--------------------------------------------------------------------------------*/}
          {/* Mobile View Toggler  [visible only after 768px screen]                         */}
          {/*--------------------------------------------------------------------------------*/}
          <span
            className="nav-toggler d-block d-md-none"
            onClick={() => showMobilemenu()}
          >
            <i className="ti-menu ti-close" />
          </span>
          {/*--------------------------------------------------------------------------------*/}
          {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
          {/*--------------------------------------------------------------------------------*/}
          <NavbarBrand href="/">
            <b className="logo-icon">
              <img src={logomini} alt="homepage" className="dark-logo" />
              <img src={logomini} alt="homepage" className="light-logo" />
            </b>
            <span className="logo-text">
              <img src={logodark} alt="homepage" className="dark-logo" />
              <img src={logolight} className="light-logo" alt="homepage" />
            </span>
          </NavbarBrand>
          {/*--------------------------------------------------------------------------------*/}
          {/* Mobile View Toggler  [visible only after 768px screen]                         */}
          {/*--------------------------------------------------------------------------------*/}
          <span
            className="topbartoggler d-block d-md-none"
            onClick={toggle.bind(null)}
          >
            <i className="ti-more" />
          </span>
        </div>
        <Collapse
          className="navbarbg"
          isOpen={isOpen}
          navbar
          data-navbarbg={settings.activeNavbarBg}
        >
          <Nav className="float-left" navbar>
            <NavItem>
              <NavLink
                href="#"
                className="d-none d-md-block"
                onClick={() => sidebarHandler()}
              >
                <i className="ti-menu" />
              </NavLink>
            </NavItem>
          </Nav>
          <Nav className="ml-auto float-right" navbar>
            {/*  */}
            {/*--------------------------------------------------------------------------------*/}
            {/* End Mega Menu Dropdown                                                         */}
            {/*--------------------------------------------------------------------------------*/}
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Create New Dropdown                                                      */}
            {/*--------------------------------------------------------------------------------*/}
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>
                {lang === "en" ? (
                  <i className="flag-icon flag-icon-us"></i>
                ) : (
                  <i className="flag-icon flag-icon-ru"></i>
                )}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => changeLang("ru")}>
                  <i className="flag-icon flag-icon-ru"></i> Русский
                </DropdownItem>
                <DropdownItem onClick={() => changeLang("en")}>
                  <i className="flag-icon flag-icon-us"></i> English
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            {/*--------------------------------------------------------------------------------*/}
            {/* End Create New Dropdown                                                        */}
            {/*--------------------------------------------------------------------------------*/}
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Profile Dropdown                                                         */}
            {/*--------------------------------------------------------------------------------*/}
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret className="pro-pic">
                <img
                  src={profilephoto}
                  alt="user"
                  className="rounded-circle"
                  width="31"
                />
              </DropdownToggle>
              <DropdownMenu right className="user-dd">
                <div className="d-flex no-block align-items-center p-3 bg-info text-white mb-2">
                  <div className="">
                    <img
                      src={profilephoto}
                      alt="user"
                      className="rounded-circle"
                      width="60"
                    />
                  </div>
                  <div className="ml-2">
                    <h4 className="mb-0 text-white">
                      {localStorage.getItem("login")}
                    </h4>
                  </div>
                </div>
                <DropdownItem onClick={() => history.push("/settings")}>
                  <i className="ti-user mr-1 ml-1" /> {t("header_acc")}
                </DropdownItem>

                <DropdownItem divider />
                <DropdownItem onClick={() => history.push("/balance")}>
                  <i className="ti-wallet mr-1 ml-1" />
                  {t("header_balance")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() => {
                    const theme = localStorage.getItem("theme");
                    const lang = localStorage.getItem("lang");
                    localStorage.clear();
                    localStorage.setItem("theme", theme);
                    localStorage.setItem("lang", lang);
                    history.push("/auth");
                  }}
                >
                  <i className="fa fa-power-off mr-1 ml-1" />{" "}
                  {t("header_logout")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            {/*--------------------------------------------------------------------------------*/}
            {/* End Profile Dropdown                                                           */}
            {/*--------------------------------------------------------------------------------*/}
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
