import UsersTable from "./UserManage";
import AccountList from "./AccountList";
import MainPage from "./MainPage";
import Statistic from "./Statistic";
import Strategies from "./Strategies";
import Margin from "./Margin";
import Orders from "./Orders";

const webRoutes = [
  {
    path: "/main",
    name: "routes_name_main",
    icon: "home",
    role: "user",
    component: MainPage,
  },
  {
    path: "/users",
    name: "routes_name_users",
    icon: "users",
    role: "admin",
    component: UsersTable,
  },
  {
    path: "/strategies",
    name: "routes_name_strat",
    icon: "activity",
    role: "admin",
    component: Strategies,
  },
  {
    path: "/accounts",
    name: "routes_name_accounts",
    icon: "grid",
    role: "user",
    component: AccountList,
  },
  {
    path: "/statistic",
    name: "routes_name_stat",
    icon: "bar-chart",
    role: "user",
    component: Statistic,
  },
  {
    path: "/margin",
    name: "routes_name_margin",
    icon: "dollar-sign",
    role: "user",
    component: Margin,
  },
  {
    path: "/orders",
    name: "routes_name_orders",
    icon: "shopping-bag",
    role: "user",
    component: Orders,
  },
  // {
  //   collapse: true,
  //   path: "/admin123",
  //   name: "Dashboards",
  //   state: "dashboardpages",
  //   icon: "users",
  //   badges: "side-badge badge badge-info",
  //   badgeno: "3",
  //   role: "user",
  //   child: [
  //     {
  //       path: "/admin/analytical",
  //       name: "Analytical",
  //       mini: "B",
  //       icon: "mdi mdi-adjust",
  //       role: "user",
  //       component: Analytical,
  //     },
  //     {
  //       path: "/admin/demographical",
  //       name: "Demographical",
  //       mini: "B",
  //       icon: "mdi mdi-adjust",
  //       role: "user",
  //       component: Demographical,
  //     },
  //     {
  //       path: "/admin/modern",
  //       name: "Modern",
  //       mini: "B",
  //       icon: "mdi mdi-adjust",
  //       role: "user",
  //       component: Modern,
  //     },
  //   ],
  // },
];

export default webRoutes;
