import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
import { api } from "../api/api";
import ModalRequest from "./ModalRequest";
import CustomTable from "./CustomTable";

const roleList = [
  { value: "admin", label: "admin" },
  { value: "user", label: "user" },
];

const UsersTable = (props) => {
  const { t } = props;
  const [modal, setModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [obj, setObj] = useState({});
  const [data, setData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [roleEmpty, setRoleEmpty] = useState(false);
  const [reload, setReload] = useState(false);
  const [requestAction, setRequsetAction] = useState(null);

  useEffect(() => {
    api.getUsersList().then((res) => {
      if (res.status === 200) {
        setData(res.data);
      }
    });
  }, [reload]);

  const toggle = () => {
    setModal((modal) => !modal);
  };

  const toggleAdd = () => {
    setAddModal((modal) => !modal);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let id = event.target.id.value;
    let password = event.target.password.value;
    if (roles.length === 0) {
      setRoleEmpty(true);
      return;
    }
    api.updateUserRole(id, roles).then((e) => {
      if (password) api.updateUserPass(id, password).then(() => {});
      setModal(!modal);
      setRoles([]);
      setReload((prev) => !prev);
    });
  };

  const handleAddSubmit = (event) => {
    event.preventDefault();
    let username = event.target.name.value;
    let password = event.target.password.value;

    api.addUser(username, password, roles).then((res) => {
      if (res.status === 200) {
        setAddModal((prev) => !prev);
        setReload((prev) => !prev);
      }
    });
  };

  const deleteUser = (obj) => {
    api.deleteUser(obj.id).then(
      (res) => {
        if (res.status === 202) {
          setReload((prev) => !prev);
        }
      },
      (rej) => {}
    );
  };

  const data2 = data.map((prop) => {
    return {
      id: prop.id,
      name: <strong>{prop.username}</strong>,
      role: prop.roles.join(", "),
      actions: (
        // we've added some custom button actions
        <div>
          {/* use this button to add a edit kind of action */}

          <Button
            onClick={() => {
              let obj = data2.find((o) => o.id === prop.id);
              setModal(!modal);
              setObj(obj);
              setRoles(obj.role.split(", "));
            }}
            color="primary"
            size="sm"
            round="true"
            icon="true"
          >
            <i className="fa fa-edit" />
          </Button>
          <Button
            onClick={() => {
              setRequsetAction(prop);
            }}
            className="ml-1"
            color="danger"
            size="sm"
            round="true"
            icon="true"
          >
            <i className="fa fa-times" />
          </Button>
        </div>
      ),
    };
  });

  return (
    <div>
      {requestAction && (
        <ModalRequest
          t={t}
          modalText={t("user_submit")}
          onCancel={() => setRequsetAction(null)}
          onSubmit={deleteUser}
          obj={requestAction}
        />
      )}
      <Modal isOpen={modal} toggle={toggle.bind(null)}>
        <ModalHeader toggle={toggle.bind(null)}>
          {t("users_modalEdit_title")}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => handleSubmit(event)}>
            <Input type="hidden" name="id" id="id" defaultValue={obj.id} />
            {/* <FormGroup>
              <Label for="name">Username</Label>
              <Input
                type="text"
                name="name"
                id="name"
                defaultValue={obj.name}
              />
            </FormGroup> */}
            <FormGroup>
              <Label for="password">{t("users_modalEdit_pass")}</Label>
              <Input
                type="text"
                name="password"
                id="password"
                defaultValue={obj.password}
              />
            </FormGroup>
            <FormGroup>
              <Label for="role">{t("users_modalEdit_roles")}</Label>
              <Select
                name="role"
                id="role"
                defaultValue={roles.map((value) => ({ value, label: value }))}
                placeholder={t("users_modalEdit_rolesPh")}
                isMulti
                onChange={(target) => {
                  if (roleEmpty) {
                    setRoleEmpty(false);
                  }
                  setRoles(target.map((val) => val.value));
                }}
                options={roleList}
              />
              {roleEmpty && (
                <div style={{ color: "red" }}>
                  {t("users_modalEdit_rolesErr")}
                </div>
              )}
            </FormGroup>

            <FormGroup>
              <Button color="primary" type="submit">
                {t("users_modalEdit_btnOk")}
              </Button>
              <Button
                color="secondary"
                className="ml-1"
                onClick={toggle.bind(null)}
              >
                {t("users_modalEdit_btnCncl")}
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>

      {/*ADD MODAL */}
      {/*ADD MODAL */}
      {/*--------------------------------------------------------------------------------*/}
      {/* Start Inner Div*/}
      {/*--------------------------------------------------------------------------------*/}

      {/*--------------------------------------------------------------------------------*/}
      {/*End Inner Div*/}
      {/*--------------------------------------------------------------------------------*/}
      {/*--------------------------------------------------------------------------------*/}
      {/* Start Action table*/}
      {/*--------------------------------------------------------------------------------*/}
      <Card>
        <CardTitle className="mb-0 p-3 border-bottom bg-light">
          <i className="mdi mdi-border-right mr-2"></i> {t("users_title")}
        </CardTitle>
        <Button
          onClick={() => {
            setAddModal((prev) => !prev);
            setObj({});
          }}
          color="success"
          size="sm"
          round="true"
          icon="true"
          style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
        >
          <i className="fas fa-plus-square" />
          <span style={{ paddingLeft: "10px" }}>{t("users_btn")}</span>
        </Button>
        <CardBody>
          <CustomTable
            t={t}
            header={[
              {
                Header: t("user_table_head_1"),
                accessor: "name",
              },

              {
                Header: t("user_table_head_2"),
                accessor: "role",
              },
              {
                Header: t("user_table_head_3"),
                accessor: "actions",
              },
            ]}
            body={data2}
          />
        </CardBody>
      </Card>
      <Modal isOpen={addModal} toggle={toggleAdd.bind(null)}>
        <ModalHeader toggle={toggleAdd.bind(null)}>
          {t("users_modalAdd_title")}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => handleAddSubmit(event)}>
            <Input type="hidden" name="id" id="id" defaultValue={obj.id} />
            <FormGroup>
              <Label for="name">{t("users_modalAdd_name")}</Label>
              <Input
                type="text"
                name="name"
                id="name"
                defaultValue={obj.name}
              />
            </FormGroup>
            <FormGroup>
              <Label for="password">{t("users_modalEdit_pass")}</Label>
              <Input
                type="text"
                name="password"
                id="password"
                defaultValue={obj.password}
              />
            </FormGroup>
            <FormGroup>
              <Label for="role">{t("users_modalEdit_roles")}</Label>
              <Select
                name="role"
                id="role"
                placeholder={t("users_modalEdit_rolesPh")}
                isMulti
                onChange={(target) => setRoles(target.map((val) => val.value))}
                options={roleList}
              />
            </FormGroup>
            <FormGroup>
              <Button color="primary" type="submit">
                {t("users_modalAdd_btnOk")}
              </Button>
              <Button
                color="secondary"
                className="ml-1"
                onClick={toggleAdd.bind(null)}
              >
                {t("users_modalEdit_btnCncl")}
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UsersTable;
