import React, { useState, useEffect, useLayoutEffect } from "react";
import { Stats2, SalesOverview } from "./dashboard";
import { DateTime } from "luxon";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Input,
} from "reactstrap";
import Select from "react-select";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { api } from "../api/api";
import TradingViewWidget from "react-tradingview-widget";
import { useHistory } from "react-router-dom";
import PaginationComponent from "react-reactstrap-pagination";
import { useSelector } from "react-redux";
import StatisticCards from "./StatisticCards";

const MainPage = (props) => {
  const { t } = props;
  const { statisticDict } = useSelector(state => state.dictionary)
  const [accountData, setAccountData] = useState([]);
  const [dealsData, setDealsData] = useState(null);
  const [listAsset, setListAsset] = useState([]);
  const [listCurrency, setListCurrency] = useState([]);
  const date = new Date();
  date.setDate(date.getDate() - 30);

  const [filter, setFilter] = useState({
    account: "",
    asset: "",
    symbol: "",
    from: date,
    to: new Date(),
    limit: "",
    page: "",
  });

  const [modal, setModal] = useState(null);

  const history = useHistory();

  useLayoutEffect(() => {
    api.getAssetList().then(
      (res) => {
        if (res.status === 200) {
          setListAsset([{value: null, label: '---'}, ...res.data.map((e) => ({ value: e.key, label: e.value }))]);
        }
      },
      (rej) => {}
    );

    api.getCurrencyList().then(
      (res) => {
        if (res.status === 200) {
          setListCurrency([{value: null, label: '---'}, ...res.data.map((e) => ({ value: e.key, label: e.value }))]);
        }
      },
      (rej) => {}
    );
  }, []);

  useEffect(() => {
    api.getAccountsDictionary().then(
      (res) => {
        if (res.status === 200) {
          setAccountData([{value: null, label: '---'},...res.data.map((e) => ({ value: e.key, label: e.value }))]);
        }
      },
      (rej) => {
        const status = rej.response.status;
        if (status === 401) {
          localStorage.clear();
          window.location.href = "/";
        } else if (status === 403) {
        }
      }
    );
  }, []);

  const showDeals = (account, asset, symbol, from, to, limit, page) => {
    if (from) {
      from = from.toJSON();
    }
    if (to) {
      to = to.toJSON();
    }
    api.getAccountDeals(account, asset, symbol, from, to, limit, page).then(
      (res) => {
        if (res.status === 200) {
          setDealsData({ accountId: account, data: res.data });
        }
      },
      (rej) => {
        const status = rej.response.status;
        if (status === 401) {
          localStorage.clear();
          window.location.href = "/";
        } else if (status === 403) {
        }
      }
    );
  };

  const toggle = () => {
    setModal((_) => null);
  };

  const closeDeal = (id, amount) => {
    api.closeDeal(id, amount).then(
      (res) => {
        if (res.status === 201) {
          toggle();
          setFilter({ ...filter });
        }
      },
      (rej) => {}
    );
  };

  useEffect(() => {
    showDeals(
      filter.account,
      filter.asset,
      filter.symbol,
      filter.from,
      filter.to,
      filter.limit,
      filter.page
    );
  }, [filter]);

  return (
    <div>
      <Modal isOpen={!!modal} toggle={toggle.bind(null)}>
        <ModalHeader toggle={toggle.bind(null)}>
          {t("deal_closeDeal")}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(event) => {
              event.preventDefault();
              closeDeal(modal.id, event.target.amount.value);
            }}
          >
            <FormGroup>
              <Label for="amount">{t("pair_modal_amount")}</Label>
              <Input type="text" name="amount" id="amount" />
            </FormGroup>

            <FormGroup>
              <Button color="primary" type="submit">
                {t("submit_btnOk")}
              </Button>
              <Button
                color="secondary"
                className="ml-1"
                onClick={toggle.bind(null)}
              >
                {t("users_modalEdit_btnCncl")}
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>

      <Row>
        <Col sm={12} lg={12} className="trade-story">
          <FormGroup>
            <Label for="account" style={{ fontSize: "18px" }}>
              {t("main_account")}
            </Label>
            <Select
              classNamePrefix="react_select"
              name="account"
              id="account"
              placeholder={t("main_accountph")}
              onChange={(e) =>
                setFilter({ ...filter, account: e.value, page: 1 })
              }
              options={accountData}
            />
          </FormGroup>
          <FormGroup>
            <Label for="currency" style={{ fontSize: "18px" }}>
              {t("main_currency")}
            </Label>
            <Select
              classNamePrefix="react_select"
              name="currency"
              id="currency"
              onChange={(e) =>
                setFilter({ ...filter, asset: e.value, page: 1 })
              }
              placeholder={t("main_currencyph")}
              options={[
                {
                  options: listCurrency,
                },
              ]}
            />
          </FormGroup>
          <FormGroup>
            <Label for="pair" style={{ fontSize: "18px" }}>
              {t("main_pair")}
            </Label>
            <Select
              classNamePrefix="react_select"
              name="pair"
              id="pair"
              onChange={(e) =>
                setFilter({ ...filter, symbol: e.value, page: 1 })
              }
              placeholder={t("main_pairph")}
              options={[
                {
                  options: listAsset,
                },
              ]}
            />
          </FormGroup>
          <FormGroup>
            <Label for="dateOt" style={{ fontSize: "18px" }}>
              {t("main_date1")}
            </Label>
            <Datetime
              name="dateOt"
              id="dateOt"
              locale="en-gb"
              closeOnSelect
              onChange={(e) => {
                if (e._d) setFilter({ ...filter, from: e._d, page: 1 });
              }}
              initialValue={filter.from}
              inputProps={{ placeholder: t("main_dateph") }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="datePo" style={{ fontSize: "18px" }}>
              {t("main_date2")}
            </Label>
            <Datetime
              name="datePo"
              id="datePo"
              locale="en-gb"
              initialValue={filter.to}
              closeOnSelect
              onChange={(e) => {
                if (e._d) setFilter({ ...filter, to: e._d, page: 1 });
              }}
              inputProps={{ placeholder: t("main_dateph") }}
            />
          </FormGroup>
        </Col>
      </Row>

      {dealsData && dealsData.data.stats.length > 0 && (
        <StatisticCards
          dictionary={statisticDict}
          className='statistic_cards mb-4 pt-0'
          data={dealsData.data.stats}
        />
        // <Row>
        //   {/* <Col sm={12} lg={12}>
        //     <SalesOverview t={t} items={dealsData.data.stats.items.reverse()} />
        //   </Col> */}
        //   <Col
        //     sm={12}
        //     lg={12}
        //     style={{ display: "flex", padding: 0, flexWrap: "wrap" }}
        //   >
        //     <Stats2
        //       t={t}
        //       values={{
        //         balance: dealsData.data.stats.balance,
        //         change: dealsData.data.stats.change,
        //         total: dealsData.data.stats.total,
        //       }}
        //     />
        //   </Col>
        // </Row>
      )}
      
      <Row>
        <Col md="12">
          <Card>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">{t("main_table_id")}</th>
                  <th scope="col">{t("main_table_time1")}</th>
                  <th scope="col">{t("main_table_time2")}</th>
                  <th scope="col">{t("main_table_currency")}</th>
                  <th scope="col">{t("main_table_amount1")}</th>
                  <th scope="col">{t("main_table_amount2")}</th>
                  <th scope="col">{t("main_table_borrowed")}</th>
                  <th scope="col">{t("main_table_profit")}</th>
                </tr>
              </thead>
              <tbody>
                {dealsData &&
                  dealsData.data.deals.map((deal) => {
                    return (
                      <tr key={deal.id}>
                        <th scope="row">
                          <span
                            className="trade-details"
                            onClick={() => {
                              history.push(`deal/${deal.id}`);
                            }}
                          >
                            <i
                              className="fa fa-eye"
                              style={{
                                color:
                                  localStorage.getItem("theme") === "dark"
                                    ? "#fff"
                                    : "#000",
                              }}
                            />
                            {deal.id}
                          </span>
                        </th>
                        <td>
                          {DateTime.fromISO(`${deal.opened}+00:00`).toFormat(
                            "yyyy-MM-dd HH:mm"
                          )}
                        </td>
                        <td>
                          {deal.closed ? (
                            DateTime.fromISO(`${deal.closed}+00:00`).toFormat(
                              "yyyy-MM-dd HH:mm"
                            )
                          ) : (
                            <Button
                              onClick={() => setModal({ id: deal.id })}
                              color="primary"
                            >
                              {t("deal_table_btnClose")}
                            </Button>
                          )}
                        </td>
                        <td>{`${deal.base}/${deal.quote}`}</td>

                        <td>{deal.income.toFixed(2)}</td>
                        <td>{deal.outcome.toFixed(2)}</td>
                        <td>{deal.borrowed ? deal.borrowed : 0}</td>
                        <td
                          style={{
                            color:
                              deal.profit.dirty.amount > 0 ? "green" : "red",
                          }}
                        >
                          {deal.closed
                            ? `${deal.profit.dirty.amount.toFixed(
                                1
                              )}/${deal.profit.dirty.percent.toFixed(1)}%`
                            : " "}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {dealsData && (
              <Row>
                <Col sm={12} lg={12} className="trade-story-pagination">
                <PaginationComponent
                  totalItems={dealsData.data.page.last}
                  pageSize={1}
                  onSelect={(e) => {
                    if (!!dealsData.data.page.last) {
                      setFilter({ ...filter, page: e })
                    }
                    }}
                  maxPaginationNumbers={8}
                  defaultActivePage={1}
                  firstPageText='«'
                  previousPageText='‹'
                  nextPageText='›'
                  lastPageText='»'
                />
                <div style={{ marginLeft: "10px", marginBottom: '1rem' }}>
                  <span style={{ marginRight: "5px" }}>
                    {t("main_table_stroke")}:
                  </span>
                  <select
                    style={{ height: "100%" }}
                    defaultValue={dealsData.data.page.limit}
                    onChange={(e) => {
                      setFilter({ ...filter, limit: e.target.value });
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                  </select>
                </div>
        
                </Col>
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export const DealDetails = (props) => {
  const { t } = props;
  const [dealDetails, setDealDetails] = useState(null);
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [strategy, setStrategy] = useState(null);

  useEffect(() => {
    const deal = history.location.pathname.slice(6);

    if (deal) {
      api.getDealDetails(deal).then(
        (res) => {
          if (res.status === 200) {
            setDealDetails({
              dealId: deal.id,
              ...res.data,
            });
          }
        },
        (rej) => {
          const status = rej.response.status;
          if (status === 401) {
            localStorage.clear();
            window.location.href = "/";
          } else if (status === 403) {
          }
        }
      );
    } else {
      history.push("/main");
    }
  }, []);

  useEffect(() => {
    if (modal) {
      api.getStrategies(dealDetails.strategy).then(
        (res) => {
          if (res.status === 200) {
            setStrategy(res.data);
          }
        },
        (rej) => {}
      );
    }
  }, [modal]);

  const toggle = () => {
    setModal((modal) => !modal);
  };

  return (
    dealDetails && (
      <>
        <Modal isOpen={modal} toggle={toggle.bind(null)}>
          <ModalHeader toggle={toggle.bind(null)}>
            <strong style={{ fontSize: "17px" }}>{t("strategy_info")}</strong>
          </ModalHeader>
          <ModalBody>
            {strategy && (
              <div style={{ padding: "10px", fontSize: "16px" }}>
                <Row>
                  <strong style={{ marginRight: "5px" }}>Key:</strong>{" "}
                  {strategy.key}
                </Row>
                <Row>
                  <strong style={{ marginRight: "5px" }}>Name:</strong>{" "}
                  {strategy.name}
                </Row>
                <Row>
                  <strong style={{ marginRight: "5px" }}>Description:</strong>{" "}
                  {strategy.description}
                </Row>
                <Row>
                  <strong style={{ marginRight: "5px" }}>Version:</strong>{" "}
                  {strategy.version}
                </Row>
                <Row>
                  <strong style={{ marginRight: "5px" }}>Updated:</strong>{" "}
                  {strategy.updated.split(/\.|\T/g).slice(0, 2).join(" ")}
                </Row>
              </div>
            )}
          </ModalBody>
        </Modal>
        <Row
          style={{
            margin: "15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            className="trade-close"
            onClick={() => {
              history.goBack();
            }}
            style={
              localStorage.getItem("theme") === "dark"
                ? { color: "#fff" }
                : { color: "#000" }
            }
          >
            <i className="fa fa-arrow-left" />
            <span>Back</span>
          </div>
          <h3>Deal {history.location.pathname.slice(6)}</h3>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <Table responsive>
                <thead>
                  <tr>
                    <th scope="col">{t("main_table_id")}</th>
                    <th scope="col">{t("main_table_time1")}</th>
                    <th scope="col">{t("main_table_time2")}</th>
                    <th scope="col">{t("main_table_currency")}</th>
                    <th scope="col">{t("main_table_amount1")}</th>
                    <th scope="col">{t("main_table_amount2")}</th>
                    {dealDetails.borrowed && (
                      <th scope="col">{t("main_table_borrowed")}</th>
                    )}
                    <th scope="col">{t("main_table_profit")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Button
                        color="primary"
                        style={{ marginLeft: "10px" }}
                        onClick={toggle}
                      >
                        {t("strategy")}
                      </Button>
                    </td>
                    <td>
                      {DateTime.fromISO(`${dealDetails.opened}+00:00`).toFormat(
                        "yyyy-MM-dd HH:mm"
                      )}
                    </td>
                    <td>
                      {DateTime.fromISO(`${dealDetails.closed}+00:00`).toFormat(
                        "yyyy-MM-dd HH:mm"
                      )}
                    </td>
                    <td>{`${dealDetails.base}/${dealDetails.quote}`}</td>

                    <td>{dealDetails.income.toFixed(2)}</td>
                    <td>{dealDetails.outcome.toFixed(2)}</td>
                    {dealDetails.borrowed && <td>{dealDetails.borrowed}</td>}
                    <td
                      style={{
                        color:
                          dealDetails.profit.dirty.amount > 0 ? "green" : "red",
                      }}
                    >{`${dealDetails.profit.dirty.amount.toFixed(
                      1
                    )}/${dealDetails.profit.dirty.percent.toFixed(1)}%`}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <TradingViewWidget
          symbol={`${dealDetails.exchange.toUpperCase()}:${dealDetails.base}${
            dealDetails.quote
          }`}
          width="100%"
          theme={localStorage.getItem("theme") === "dark" ? "Dark" : "Light"}
          locale={localStorage.getItem("lang")}
          interval="1"
          withdateranges
          hide_side_toolbar={false}
        />
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <CardTitle>
                  <h3>{t("deal_table_title")}</h3>
                </CardTitle>
              </CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th scope="col">{t("deal_table_id")}</th>
                    <th scope="col">{t("deal_table_type")}</th>
                    <th scope="col">{t("deal_table_opened")}</th>
                    <th scope="col">{t("deal_table_closed")}</th>
                    <th scope="col">{t("deal_table_order")}</th>
                    <th scope="col">{t("deal_table_status")}</th>
                    <th scope="col">{t("deal_table_price")}</th>
                    <th scope="col">{t("deal_table_amount")}</th>
                    <th scope="col">{t("deal_table_fill")}</th>
                  </tr>
                </thead>
                <tbody>
                  {dealDetails.orders.map((order) => {
                    return (
                      <tr key={order.id}>
                        <th scope="row">{order.id}</th>
                        <td>{order.type}</td>
                        <td>
                          {DateTime.fromISO(`${order.created}+00:00`).toFormat(
                            "yyyy-MM-dd HH:mm"
                          )}
                        </td>
                        <td>
                          {DateTime.fromISO(`${order.closed}+00:00`).toFormat(
                            "yyyy-MM-dd HH:mm"
                          )}
                        </td>
                        <td>{order.side}</td>
                        <td>{order.status}</td>
                        <td>{order.price.toFixed(2)}</td>
                        <td>{`${order.amount.toFixed(8)} ${
                          dealDetails.base
                        }`}</td>
                        <td>{`${order.filled.toFixed(8)} ${
                          dealDetails.base
                        }`}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </>
    )
  );
};

export default MainPage;
