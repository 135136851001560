import React, { useState, useEffect } from "react";
import PaginationComponent from "react-reactstrap-pagination";
import {
  Table,
  Row,
  Col,
  Card,
} from "reactstrap";

const CustomTable = (props) => {
  const { header, body, t } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    if (body) {
      setLastPage(Math.ceil(body.length / rows));
    }
  }, [body, rows]);

  return (
    <Col md="12">
      <Card>
        <Table responsive>
          <thead>
            <tr>
              {header.map((e) => (
                <th scope="col" key={"header" + e.Header} style={e.style || {}}>
                  {e.Header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {body
              .slice((+currentPage - 1) * rows, +currentPage * rows)
              .map((e) => (
                <tr key={(e.key || e.id) + 20}>
                  {header.map((k) => (
                    <th key={e.id + k["accessor"]}>{e[k["accessor"]]}</th>
                  ))}
                </tr>
              ))}
          </tbody>
        </Table>
        {body && (
          <Row>
            <Col sm={12} lg={12} className="trade-story-pagination">
              <PaginationComponent
                totalItems={+lastPage}
                pageSize={1}
                onSelect={(e) => {
                    setCurrentPage(e);
                  }}
                maxPaginationNumbers={8}
                defaultActivePage={1}
                firstPageText='«'
                previousPageText='‹'
                nextPageText='›'
                lastPageText='»'
              />
              <div style={{ marginLeft: "10px", marginBottom: '1rem' }}>
                  <span style={{ marginRight: "5px" }}>
                    {t("main_table_stroke")}:
                  </span>
                  <select
                    style={{ height: "100%" }}
                    defaultValue={rows}
                    onChange={(e) => {
                      setCurrentPage(1);
                      setRows(e.target.value);
                    }}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                </div>
            </Col>
          </Row>
        )}
      </Card>
    </Col>
  );
};

export default CustomTable;
