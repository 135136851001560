import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Table,
  InputGroupAddon,
  InputGroup,
} from "reactstrap";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css";
import { api } from "../api/api";
import ModalRequest from "./ModalRequest";
import CustomTable from "./CustomTable";
import { useHistory } from "react-router-dom";
import StatisticCards from "./StatisticCards";
import { toast } from "react-toastify";
import CustomTableOnPage from "./CustomTableOnPage";

const AccountList = (props) => {
  const { t } = props;
  const objInitState = {
    id: "",
    name: { props: { children: "" } },
    exchange: "",
    trade: true,
  };
  const history = useHistory();

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modalRenew, setModalRenew] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [obj, setObj] = useState(objInitState);
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [listExchanges, setListExchanges] = useState([]);
  const [listCurrency, setListCurrency] = useState([]);
  const [accountSettings, setAccountSettings] = useState(null);
  const [requestAction, setRequsetAction] = useState(null);
  const [currentExchange, setCurrentExchange] = useState("");

  useLayoutEffect(() => {
    api.getExchangeList().then((res) => {
      if (res.status === 200) {
        setListExchanges(
          res.data.map((e) => ({ value: e.key, label: e.value }))
        );
      }
    });
    api.getCurrencyList().then((res) => {
      if (res.status === 200) {
        setListCurrency(
          res.data.map((e) => ({ value: e.key, label: e.value }))
        );
      }
    });
  }, []);

  useEffect(() => {
    api.getAccountsList().then(
      (res) => {
        if (res.status === 200) {
          setData(res.data);
        }
      },
      (rej) => {
        const status = rej.response.status;
        if (status === 401) {
          localStorage.clear();
          window.location.href = "/";
        } else if (status === 403) {
        }
      }
    );
  }, [reload]);

  const toggle = () => {
    setModal((modal) => !modal);
  };

  const toggle2 = () => {
    setModal2((modal2) => !modal2);
  };

  const toggleRenew = () => {
    setModalRenew((modalRenew) => !modalRenew);
  };

  const toggleAdd = () => {
    setAddModal((modal) => !modal);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let id = event.target.id.value;
    let name = event.target.name.value;
    let currency = event.target.currency.value;
    api.renameAccount(id, name, currency).then((res) => {
      setModal(!modal);
      setReload((prev) => !prev);
    });
  };

  const handleSubmit2 = (event) => {
    event.preventDefault();
    let id = event.target.id.value;

    let key = event.target.key.value;
    let secret = event.target.secret.value;
    let password = event.target.password.value;
    api.updateAccount(id, key, secret, password).then((res) => {
      setModal2(!modal2);
      setReload((prev) => !prev);
    });
  };

  const handleSubmitRenew = async (event) => {
    event.preventDefault();
    let id = event.target.id.value;
    let asset = event.target.asset.value;
    try {
      api.reloadBalance(id, {asset})
      setModalRenew(!modalRenew)
      setReload((prev) => !prev)
      toast.success('Asset will be update!')
    } catch(e) {
      toast.error('Something went wrong!')
    }
    
  };

  const handleAddSubmit = (event) => {
    event.preventDefault();
    let name = event.target.name.value;
    let exchange = event.target.exchange.value;
    let key = event.target.key.value;
    let secret = event.target.secret.value;
    let password = event.target.password.value;
    let asset = event.target.currency.value;

    api
      .createAccount(name, exchange, key, secret, password, asset)
      .then((res) => {
        if (res.status === 201) {
          setAddModal((prev) => !prev);
          setReload((prev) => !prev);
          setCurrentExchange("");
        }
      });
  };

  const deleteAccount = (obj) => {
    api.deleteAccount(obj.id).then(
      (res) => {
        if (res.status === 202) {
          setReload((prev) => !prev);
        }
      },
      (rej) => {}
    );
  };

  const data2 = data.map((prop) => {
    const exch = listExchanges.filter((e) => e.value === prop.exchange)[0];
    return {
      id: prop.id,
      currency: prop.asset,
      name: (
        <span
          className="account_name"
          onClick={() => {
            history.push(`/accounts/${prop.id}`)
            setReload((prev) => !prev);
            }
          }
        >
          {prop.name}
        </span>
      ),
      exchange: <span style={{paddingTop: '5px', fontSize: '17px'}}>{exch ? exch.label : prop.exchange}</span>,
      balance: <>
      {`${prop.balance.spot} / ${prop.balance.margin} ${prop.asset}`}
        <Button
          color="none"
          style={{
            padding: "0",
            height: '28px'
          }}
          onClick={() => reloadBalance(prop.id)}
        >
          <i className="mdi mdi-reload" style={{fontSize: '28px', color: '#fff', position: 'absolute', top: '-8px'}}></i>
      </Button>
      </>,
      trade: (
        <Switch
          name="trade"
          id="trade"
          defaultValue={prop.enabled}
          onChange={(e) => {
            api.changeTrading(e.state.value, prop.id);
          }}
        />
      ),
      repay_button: (
        <Button
            onClick={() => {
              repayAccount(prop.id)
            }}
            color="warning"
            size="sm"
            round="true"
          >
            Repay All
          </Button>
      ),
      actions: (
        <div>
          <Button
            onClick={() => {
              let obj = data2.find((o) => o.id === prop.id);
              setModalRenew(true);
              setObj(obj);
            }}
            color="primary"
            size="sm"
            round="true"
            icon="true"
          >
            <i className="fa fa-dollar-sign" />
          </Button>
          <Button
            onClick={() => {
              let obj = data2.find((o) => o.id === prop.id);
              setModal(!modal);
              setObj(obj);
            }}
            color="primary"
            className="ml-1"
            size="sm"
            round="true"
            icon="true"
          >
            <i className="fa fa-edit" />
          </Button>
          <Button
            onClick={() => {
              let obj = data2.find((o) => o.id === prop.id);
              setModal2(!modal);
              setObj(obj);
            }}
            className="ml-1"
            color="primary"
            size="sm"
            round="true"
            icon="true"
          >
            <i className="fa fa-key" />
          </Button>
          <Button
            onClick={() => {
              setRequsetAction(prop);
              setObj(obj);
            }}
            className="ml-1"
            color="danger"
            size="sm"
            round="true"
            icon="true"
          >
            <i className="fa fa-times" />
          </Button>
        </div>
      ),
    };
  });

  const reloadBalance = async (id) => {
      try {
        await api.reloadBalance(id)
        toast.success('Balance updated!')
        setReload(prev => !prev)
      } catch (e) {
        toast.warn('Something went wrong!')
      }
  }

  const repayAccount = async (id) => {
    try {
      await api.repayAccount(id)
      toast.success('All repaid!')
    } catch (e) {
      toast.warn('Something went wrong!')
    }
}

  return (!history.location.pathname.replace('/accounts', '') ?
    <div>
      {requestAction && (
        <ModalRequest
          t={t}
          modalText={t("account_modat_request")}
          onCancel={() => setRequsetAction(null)}
          onSubmit={deleteAccount}
          obj={requestAction}
        />
      )}
      <Modal isOpen={modal} toggle={toggle.bind(null)}>
        <ModalHeader toggle={toggle.bind(null)}>
          {t("account_modal_edit_title2")}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => handleSubmit(event)}>
            <Input type="hidden" name="id" id="id" defaultValue={obj.id} />
            <FormGroup>
              <Label for="name">{t("account_modal_add_name1")}</Label>
              <Input
                type="text"
                name="name"
                id="name"
                defaultValue={obj.name.props.children}
              />
            </FormGroup>
            <FormGroup>
              <Label for="currency">{t("account_modal_add_name3")}</Label>
              <Select
                name="currency"
                id="currency"
                placeholder={t("account_modal_add_name3ph")}
                defaultValue={
                  listCurrency.filter((e) => e.value === obj.currency)[0]
                }
                options={listCurrency}
              />
            </FormGroup>
            <FormGroup>
              <Button color="primary" type="submit">
                {t("account_modal_edit_btnOk")}
              </Button>
              <Button
                color="secondary"
                className="ml-1"
                onClick={toggle.bind(null)}
              >
                {t("account_modal_add_btnCncl")}
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal2} toggle={toggle2.bind(null)}>
        <ModalHeader toggle={toggle2.bind(null)}>
          {t("account_modal_edit_title")}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => handleSubmit2(event)}>
            <Input type="hidden" name="id" id="id" defaultValue={obj.id} />
            <FormGroup>
              <Label for="key">{t("account_modal_add_name4")}</Label>
              <Input type="text" name="key" id="key" required />
            </FormGroup>
            <FormGroup>
              <Label for="secret">{t("account_modal_add_name5")}</Label>
              <Input type="text" name="secret" id="secret" required />
            </FormGroup>
            <FormGroup
              style={
                obj.exchange === "Kucoin" || obj.exchange === "Okex"
                  ? {}
                  : { display: "none" }
              }
            >
              <Label for="password">{t("account_modal_add_name6")}</Label>
              <Input type="text" name="password" id="password" />
            </FormGroup>
            <FormGroup>
              <Button color="primary" type="submit">
                {t("account_modal_edit_btnOk")}
              </Button>
              <Button
                color="secondary"
                className="ml-1"
                onClick={toggle2.bind(null)}
              >
                {t("account_modal_add_btnCncl")}
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalRenew} toggle={toggleRenew.bind(null)}>
        <ModalHeader toggle={toggleRenew.bind(null)}>
          {t("account_modal_renew_title")}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => handleSubmitRenew(event)}>
            <Input type="hidden" name="id" id="id" defaultValue={obj.id} />
            <FormGroup>
              <Select
                name="asset"
                id="asset"
                placeholder={t("account_modal_add_name3ph")}
                defaultValue={
                  {value: obj.currency, label: obj.currency}
                }
                options={listCurrency}
              />
            </FormGroup>
            <FormGroup>
              <Button color="primary" type="submit">
                {t("account_modal_edit_btnOk")}
              </Button>
              <Button
                color="secondary"
                className="ml-1"
                onClick={toggleRenew.bind(null)}
              >
                {t("account_modal_add_btnCncl")}
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      <CustomTableOnPage
        t={t}
        title={t("accounts_title")}
        onMainBtnClick={() => {
          setAddModal((prev) => !prev);
          setObj(objInitState);
        }}
        mainBtnText={t("accounts_btn")}
        tableHeader={[
          {
            Header: t("accounts_head_1"),
            accessor: "name",
          },
          {
            Header: t("accounts_head_2"),
            accessor: "exchange",
          },
          {
            Header: t("accounts_head_3"),
            accessor: "balance",
          },
          {
            Header: 'Repay',
            accessor: "repay_button",
          },
          {
            Header: t("accounts_head_4"),
            accessor: "trade",
          },
          {
            Header: t("accounts_head_5"),
            accessor: "actions",
          },
        ]}
        tableBody={data2}
      />
      <Modal isOpen={addModal} toggle={toggleAdd.bind(null)}>
        <ModalHeader toggle={toggleAdd.bind(null)}>
          {t("account_modal_add_title")}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => handleAddSubmit(event)}>
            <Input type="hidden" name="id" id="id" defaultValue={obj.id} />
            <FormGroup>
              <Label for="name"> {t("account_modal_add_name1")}</Label>
              <Input type="text" name="name" id="name" required />
            </FormGroup>
            <FormGroup>
              <Label for="exchange">{t("account_modal_add_name2")}</Label>
              <Select
                name="exchange"
                id="exchange"
                placeholder={t("account_modal_add_name2ph")}
                onChange={(e) => setCurrentExchange(e.value)}
                options={listExchanges}
              />
            </FormGroup>
            <FormGroup>
              <Label for="currency">{t("account_modal_add_name3")}</Label>
              <Select
                name="currency"
                id="currency"
                placeholder={t("account_modal_add_name3ph")}
                options={listCurrency}
              />
            </FormGroup>
            <FormGroup>
              <Label for="key">{t("account_modal_add_name4")}</Label>
              <Input type="text" name="key" id="key" required />
            </FormGroup>
            <FormGroup>
              <Label for="secret">{t("account_modal_add_name5")}</Label>
              <Input type="text" name="secret" id="secret" required />
            </FormGroup>
            <FormGroup
              style={
                currentExchange === "kucoin" || currentExchange === "okex"
                  ? {}
                  : { display: "none" }
              }
            >
              <Label for="password">{t("account_modal_add_name6")}</Label>
              <Input type="text" name="password" id="password" />
            </FormGroup>
            <FormGroup>
              <Button color="primary" type="submit">
                {t("account_modal_add_btnOk")}
              </Button>
              <Button
                color="secondary"
                className="ml-1"
                onClick={toggleAdd.bind(null)}
              >
                {t("account_modal_add_btnCncl")}
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </div> : <PairDetails t={t} setReload={setReload} />
  );
};

export const PairDetails = (props) => {
  const { t, setReload } = props;
  const [accountSettings, setAccountSettings] = useState(null);
  const [limitType, setLimitType] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [addModalLimit, setAddModalLimit] = useState(false);
  const [assets, setAssets] = useState(null);
  const [pair, setPair] = useState(null);
  const [requestAction, setRequsetAction] = useState(null);
  const [wallet, setWallet] = useState("");
  const history = useHistory();
  const [statisticDict, setStatisticDict] = useState([]);
  const [accountStatistic, setAccountStatistic] = useState([]);
  const [infoModal, setInfoModal] = useState(false);


  useEffect(() => {
    const path = history.location.pathname
    const id = path.slice(path.lastIndexOf('/')+1);
    if (!id) {
      history.push("/main");
      return;
    }
    api.getAssetList().then((res) => {
      if (res.status === 200) {
        setAssets(res.data.map((e) => ({ value: e.key, label: e.value })));
      }
    });

    api.getAccountInfo(id).then(
      (res) => {
        if (res.status === 200) {
          setAccountSettings(res.data);
        }
      },
      (rej) => {
        const status = rej.response.status;
        if (status === 401) {
          localStorage.clear();
          window.location.href = "/";
        } else if (status === 403) {
        }
      }
    );

    api.getStatisticDictionary().then(
      (res) => {
        if (res.status === 200) {
          const obj = {};
          res.data.map(e => {
            obj[e.key] = e.value;
          })
          setStatisticDict(obj);
        }
      },
      (rej) => {}
    );

    api.getAccountStatistic(id).then(
      (res) => {
        if (res.status === 200) {
          setAccountStatistic(res.data)
        }
      },
      (rej) => {}
    );

  }, []);

  const updatePairs = (id) => {
    api.getAccountInfo(id).then(
      (res) => {
        if (res.status === 200) {
          setAccountSettings(res.data);
        }
      },
      (rej) => {
        const status = rej.response.status;
        if (status === 401) {
          localStorage.clear();
          window.location.href = "/";
        } else if (status === 403) {
        }
      }
    );
  };

  const toggleAdd = () => {
    setAddModal((prev) => !prev);
  };

  const toggleAddLimit = () => {
    setAddModalLimit((prev) => !prev);
  };

  const handleAddSubmit = (event) => {
    event.preventDefault();
    const symbol = event.target.currency.value,
      wallet = event.target.wallet.value,
      limit = event.target.limit.value,
      reserve = event.target.reserve.value,
      parent = "USDT";
    if (wallet === "margin") {
      const spin = event.target.spin.value;
      api
        .addPair(accountSettings.id, {
          symbol,
          wallet,
          parent,
          percent: limitType,
          limit,
          spin,
          reserve,
        })
        .then(
          (res) => {
            if (res.status === 202) {
              toggleAdd();
              updatePairs(accountSettings.id);
            }
          },
          (rej) => {}
        );
    } else {
      api
        .addPair(accountSettings.id, {
          symbol,
          wallet,
          parent,
          percent: limitType,
          limit,
          reserve,
        })
        .then(
          (res) => {
            if (res.status === 202) {
              toggleAdd();
              updatePairs(accountSettings.id);
            }
          },
          (rej) => {}
        );
    }

    setWallet("");
  };

  const handleAddSubmitLimit = (event) => {
    event.preventDefault();
    const limit = event.target.limit2.value,
      spin = event.target.spin ? event.target.spin.value : "",
      wallet = event.target.wallet.value,
      reserve = event.target.reserve.value;
    api
      .changePairLimit(accountSettings.id, pair.base, pair.quote, {
        percent: limitType,
        limit,
        spin,
        wallet,
        reserve,
      })
      .then(
        (res) => {
          if (res.status === 202) {
            toggleAddLimit();
            updatePairs(accountSettings.id);
            setPair(null);
          }
        },
        (rej) => {}
      );
    setWallet("");
  };

  const deletePair = (obj) => {
    api.deletePair(obj.id, obj.base, obj.quote).then(
      (res) => {
        if (res.status === 202) {
          updatePairs(accountSettings.id);
        }
      },
      (rej) => {}
    );
  };

  const shoWInfoModal = () => {
    if (typeof infoModal === 'boolean') {
      setInfoModal(prev => !prev)
    }
    setInfoModal(false)
  }

  const getInfoPair = async (id, base, qoute) => {
    try {
      const res = await api.showPairData(id, base, qoute);
      setInfoModal(res.data.limits)
    } catch(e) {
      toast.error('Something went wrong!')
    }
  }

  return (
    accountSettings &&
    assets && (
      <>
        {requestAction && (
          <ModalRequest
            t={t}
            modalText={t("pairs_request")}
            onCancel={() => setRequsetAction(null)}
            onSubmit={deletePair}
            obj={requestAction}
          />
        )}

        <Modal isOpen={!!infoModal} toggle={shoWInfoModal}>
          <ModalHeader toggle={shoWInfoModal}>
            {t('pair_limit_title')}
          </ModalHeader>
          <ModalBody>
            <p style={{fontSize: '18px'}}>{t('pair_max')}: <strong>{infoModal.amount}</strong></p>
            <p style={{fontSize: '18px'}}>{t('pair_borrow')}: <strong>{infoModal.borrow}</strong></p>
          </ModalBody>
        </Modal>

        <Modal isOpen={addModal} toggle={toggleAdd.bind(null)}>
          <ModalHeader toggle={toggleAdd.bind(null)}>
            {t("pair_modal_title1")}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={(event) => handleAddSubmit(event)}>
              <FormGroup>
                <Label for="currency">{t("pair_modal_name1")}</Label>
                <Select
                  name="currency"
                  id="currency"
                  placeholder={t("pair_modal_name1ph")}
                  defaultValue={
                    assets.filter(
                      (e) =>
                        !accountSettings.pairs
                          .map((k) => `${k.base}/${k.quote}`)
                          .includes(e.value)
                    )[0]
                  }
                  options={assets.filter(
                    (e) =>
                      !accountSettings.pairs
                        .map((k) => `${k.base}/${k.quote}`)
                        .includes(e.value)
                  )}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="wallet">{t("pair_modal_name2")}</Label>
                <Select
                  name="wallet"
                  id="wallet"
                  isRequired
                  onChange={(e) => setWallet(e.value)}
                  placeholder={t("pair_modal_name2ph")}
                  defaultValue={{ value: "spot", label: "Spot" }}
                  options={[
                    { value: "margin", label: "Margin" },
                    { value: "spot", label: "Spot" },
                  ]}
                />
              </FormGroup>

              <FormGroup style={wallet === "margin" ? {} : { display: "none" }}>
                <Label for="spin">{t("pair_modal_name3")}</Label>
                <Input
                  type="text"
                  name="spin"
                  id="spin"
                  defaultValue={0}
                  required
                />
              </FormGroup>

              <FormGroup>
                <Label for="limit">{t("pair_modal_name4")}</Label>

                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <Button
                      className="btn btn-secondary"
                      style={{
                        background: limitType ? "#398bf7" : "#67757c",
                        border: "none",
                        zIndex: 0,
                      }}
                      // color="info"
                      onClick={(e) => {
                        e.preventDefault();
                        if (limitType) {
                          return;
                        }
                        setLimitType((prev) => !prev);
                      }}
                    >
                      {t("pair_modal_perc")}
                    </Button>
                    <Button
                      className="btn btn-secondary"
                      style={{
                        background: !limitType ? "#398bf7" : "#67757c",
                        border: "none",
                        zIndex: 0,
                      }}
                      color="info"
                      onClick={(e) => {
                        e.preventDefault();
                        if (!limitType) {
                          return;
                        }
                        setLimitType((prev) => !prev);
                      }}
                    >
                      {t("pair_modal_amount")}
                    </Button>
                  </InputGroupAddon>
                  <Input type="text" name="limit" id="limit" required />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label for="reserve">{t("pair_head_name7")}</Label>
                <Input type="text" name="reserve" id="reserve" required />
              </FormGroup>
              <FormGroup>
                <Button color="primary" type="submit">
                  {t("account_modal_add_btnOk")}
                </Button>
                <Button
                  color="secondary"
                  className="ml-1"
                  onClick={toggleAdd.bind(null)}
                >
                  {t("account_modal_add_btnCncl")}
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>

        <Modal isOpen={addModalLimit} toggle={toggleAddLimit.bind(null)}>
          <ModalHeader toggle={toggleAddLimit.bind(null)}>
            {t("pair_modal_title2")}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={(event) => handleAddSubmitLimit(event)}>
              <FormGroup>
                <Label for="wallet">{t("pair_modal_name2")}</Label>
                <Select
                  name="wallet"
                  id="wallet"
                  isRequired
                  onChange={(e) => setWallet(e.value)}
                  placeholder={t("pair_modal_name2ph")}
                  defaultValue={
                    pair && pair.wallet === "margin"
                      ? { value: "margin", label: "Margin" }
                      : { value: "spot", label: "Spot" }
                  }
                  options={[
                    { value: "margin", label: "Margin" },
                    { value: "spot", label: "Spot" },
                  ]}
                />
              </FormGroup>

              <FormGroup style={wallet === "margin" ? {} : { display: "none" }}>
                <Label for="spin">{t("pair_modal_name3")}</Label>
                <Input
                  type="text"
                  name="spin"
                  id="spin"
                  required
                  defaultValue={pair ? pair.spin : ""}
                />
              </FormGroup>
              <FormGroup>
                <Label for="limit2">{t("pair_modal_name4")}</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <Button
                      style={{
                        background: limitType ? "#398bf7" : "#67757c",
                        border: "none",
                        zIndex: 0,
                      }}
                      className="btn btn-secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        if (limitType) {
                          return;
                        }
                        setLimitType((prev) => !prev);
                      }}
                    >
                      {t("pair_modal_perc")}
                    </Button>
                    <Button
                      style={{
                        background: !limitType ? "#398bf7" : "#67757c",
                        border: "none",
                        zIndex: 0,
                      }}
                      className="btn btn-secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        if (!limitType) {
                          return;
                        }
                        setLimitType((prev) => !prev);
                      }}
                    >
                      {t("pair_modal_amount")}
                    </Button>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name="limit2"
                    id="limit2"
                    required
                    defaultValue={pair ? pair.options.limit : ""}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label for="reserve">{t("pair_head_name7")}</Label>
                <Input
                  type="text"
                  name="reserve"
                  id="reserve"
                  required
                  defaultValue={pair ? pair.options.reserve : ""}
                />
              </FormGroup>
              <FormGroup>
                <Button color="primary" type="submit">
                  {t("users_modalAdd_btnOk")}
                </Button>
                <Button
                  color="secondary"
                  className="ml-1"
                  onClick={toggleAddLimit.bind(null)}
                >
                  {t("submit_btnCnlc")}
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>

        <Row
          style={{
            margin: "15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            className="account-close"
            onClick={() => {
              setAccountSettings(null);
              history.push("/accounts");
              setReload(prev => !prev)
            }}
            style={
              localStorage.getItem("theme") === "dark"
                ? { color: "#fff" }
                : { color: "#000" }
            }
          >
            <i className="fa fa-arrow-left" />
            <span>{t("pair_back")}</span>
          </div>
          <h3>
            {t("pair_account")} <strong>{accountSettings.name}</strong>
          </h3>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardTitle
                className="mb-0 p-3 border-bottom bg-light"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span style={{ fontSize: "18px" }}>{t("pair_title")}</span>
                <Button color="info" onClick={toggleAdd.bind(null)}>
                  {t("pair_btn")}
                </Button>
              </CardTitle>
              <Table responsive>
                <thead>
                  <tr>
                    <th scope="col">{t("pair_head_name1")}</th>
                    <th scope="col">{t("pair_head_name2")}</th>
                    <th scope="col">{t("pair_head_name3")}</th>
                    <th scope="col">{t("pair_head_name7")}</th>
                    <th scope="col">{t("pair_head_name4")}</th>
                    <th scope="col">{t("pair_head_name5")}</th>
                    <th scope="col">{t("pair_head_name6")}</th>
                  </tr>
                </thead>
                <tbody>
                  {accountSettings.pairs.map((pair, id) => {
                    return (
                      <tr key={pair + id}>
                        <th scope="row">{`${pair.base}/${pair.quote}`}</th>
                        <td>{pair.wallet}</td>
                        <td>{`${pair.options.limit}${
                          pair.options.percent ? "%" : pair.quote
                        }`}</td>
                        <td>{pair.options.reserve}</td>
                        <td>{pair.options.spin}</td>
                        <td>
                          <Switch
                            name="pair"
                            id="pair"
                            defaultValue={pair.enabled}
                            onChange={(e) => {
                              api.changePairsStatus(
                                accountSettings.id,
                                pair.base.toLowerCase(),
                                pair.quote.toLowerCase(),
                                e.state.value
                              );
                            }}
                            onColor="success"
                            offColor="danger"
                          />
                        </td>
                        <td>
                        <Button
                            color="warning"
                            style={{ marginRight: "5px" }}
                            onClick={() => {
                              getInfoPair(accountSettings.id, pair.base, pair.quote)
                            }}
                          >
                            <i className="fa fa-info" />
                          </Button>
                          <Button
                            color="primary"
                            style={{ marginRight: "5px" }}
                            onClick={() => {
                              setPair(pair);
                              setLimitType(pair.options.percent);
                              toggleAddLimit();
                              setWallet(pair.wallet);
                            }}
                          >
                            <i className="fa fa-edit" />
                          </Button>
                          <Button
                            color="danger"
                            onClick={() => {
                              setRequsetAction({
                                id: accountSettings.id,
                                ...pair,
                              });
                            }}
                          >
                            <i className="fa fa-times" />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <StatisticCards
          dictionary={statisticDict}
          data={accountStatistic}
          className="statistic_cards"
        />
      </>
    )
  );
};

export default AccountList;
