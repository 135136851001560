import {
  EXCHANGE_SPOT,
  EXCHANGE_MARGIN,
  ASSETS,
  SYMBOLS,
  STATISTIC_DICT
} from "../constants/";

const INIT_STATE = {
  exchangeMargin: [],
  exchangeSpot: [],
  assets: [],
  symbols: [],
  statisticDict: []
}


const ExchangeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case EXCHANGE_SPOT:
      return {
        ...state,
        exchangeSpot: action.payload,
      };
    case EXCHANGE_MARGIN:
      return {
        ...state,
        exchangeMargin: action.payload,
      }
    case ASSETS:
      return {
        ...state,
        assets: action.payload,
      }
    case SYMBOLS:
      return {
        ...state,
        symbols: action.payload,
      }
    case STATISTIC_DICT: 
      return {
        ...state,
        statisticDict: action.payload,
      }
    default:
      return state;
  }
};

export default ExchangeReducer;
